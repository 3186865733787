
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import myMixin from './mixin-master-detail.js';
export default {
  mixins: [myMixin],
  data() {
    return {
      _PRICE_A: this.$root.FB.GLOBALCONFIG.priceA,
      _PRICE_B: this.$root.FB.GLOBALCONFIG.priceB,
      _DB_TABLE: 'invoices',
      _CACHE_TABLE: 'INVS',
      _MASTER_PAGE: '/invoices/',
      _MASTER_EVENT: 'refreshInvoiceData',
      _INIT_BUY: false,
      totalPrice: '0',
      ACT_DATE: new Date().toISOString().split('T')[0],
      MIN_DATE: this.$root.U.addDays(-5).toISOString().split('T')[0],
      MAX_DATE: this.$root.U.addDays(30).toISOString().split('T')[0]
    };
  },
  on: {
    pageInit(e, page) {
      this._ID = this.$route.params.id;
      this._URL_CACHE = this.$root.FB.CACHE[this._CACHE_TABLE];
      if (this._URL_CACHE === null)
        this._URL_CACHE = {};
      this.FORM_DATA_ORIG = this._URL_CACHE[this._ID];
      this._UPDATE_DB_PATH = '/' + this._DB_TABLE + '/' + this.$root.FB.getUID() + '/' + (this.$route.params.id == -1 ? '' : this.$route.params.id);
      this.$app.on('RT_NEWINV', this.reUpdateActual);
      this.$app.on('changeToBuy', this.reUpdateActual);
      if (this._ID != -1) {
        this.totalPrice = this.FORM_DATA_ORIG.imp * 1 <= 2000 ? this._PRICE_A : this._PRICE_B;
        this.$update();
      }
    },
    pageBeforeRemove() {
      this.$app.off('changeToBuy', this.reUpdateActual);
      this.$app.off('RT_NEWINV', this.reUpdateActual);
    },
    pageBeforeIn: function (e, page) {
      this.getInitData();
      this._INIT_BUY = this.FORM_DATA.buy;
    },
    pageAfterIn: function () {
      this.$app.emit('selMasterItem', this._ID);
    }
  },
  methods: {
    cookData4DB(_F) {
      _F.c = 'DD_TIMESTAMP';
      _F.cifT = _F.cifT.toUpperCase().replace(/ /g, '').replace(/-/g, '');
      return _F;
    },
    showToolbar() {
      if (this._INIT_BUY)
        return;
      var _el = this.$$(this.$el);
      _el.find('.xtoolbar').removeClass('display-none');
      _el.find('.Istatus').addClass('display-none');
    },
    hideToolbar() {
      var _el = this.$$(this.$el);
      _el.find('.xtoolbar, .Istatus').addClass('display-none');
      _el.find('.Istatus').removeClass('display-none');
      if (this._ID != -1) {
        this.totalPrice = this.FORM_DATA_ORIG.imp * 1 <= 2000 ? this._PRICE_A : this._PRICE_B;
        this.$update();
      }
    },
    pageFullyLoaded() {
      if (this._ID == '-1')
        this.$el.find('INPUT[name="num"]').focus();
    },
    reUpdateActual() {
      this._URL_CACHE = this.$root.FB.CACHE[this._CACHE_TABLE];
      this.FORM_DATA_ORIG = this._URL_CACHE[this._ID];
      this.$setState({ FORM_DATA: this.$root.U.cloneO(this._URL_CACHE[this._ID]) });
    },
    buyItem() {
      var that = this;
      var U = this.$root.U;
      if (this.$('.input-invalid').length) {
        this.$root.U.showErrorWindow(this.$root.U.getL('accB'));
        return;
      }
      if (!this.$('#acceptOK').prop('checked')) {
        this.$el.find('.miniOK').addClass('mOKred');
        this.$root.U.showErrorWindow(this.$root.U.getL('accA'));
        return;
      }
      if (this._ID != -1) {
        this.$app.dialog.confirm(U.getL('pk2'), U.getL('bP'), async function () {
          that.$root.U.showLoader();
          that.$root.FB.functions.httpsCallable('BI')({ i: that._ID }).then(function (result) {
            if (result.data && result.data.res == 'OK') {
              that.$root.FB.CACHE['INVS'][that._ID]['buy'] = true;
              that.$app.emit('changeToBuy');
            } else {
              that.$root.FB._ERROR(result.data && result.data.status ? result.data.status : 'errr ');
            }
            that.$update(that.$root.U.hideLoader);
          }).catch(function (error) {
            that.$root.U.hideLoader();
            that.$root.FB._ERROR(error);
          });
        });
      }
    },
    downloadPDF() {
      var that = this;
      this.$root.U.showLoader();
      if (that.$root.FB.CACHE['INVS'][that._ID]['imp'] == '1.11') {
        that.$root.U.hideLoader();
        that.$root.U.openNewWin('https://partners.aseguratufactura.es/pdf/pol_demo.pdf');
        return;
      }
      ;
      try {
        this.getBlobUrlFromPdfStream().then(blobUrl => {
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = 'Poliza_BODA.pdf';
          link.click();
          that.$root.U.hideLoader();
        }).catch(err => console.log(err));
      } catch (x) {
        this.$root.FB._ERROR(that.$root.U.getL('e017'));
        this.$root.U.hideLoader();
      }
    },
    async getBlobUrlFromPdfStream() {
      var that = this;
      return new Promise(async (resolve, reject) => {
        var tk = await this.$root.FB.auth.currentUser.getIdToken();
        fetch(that.$root.FB.GLOBALCONFIG.strapi + '/pdf', {
          method: 'POST',
          headers: { 'Authorization': 'Bearer ' + tk },
          body: JSON.stringify({ i: that._ID })
        }).then(response => response.body).then(body => {
          const reader = body.getReader();
          return new ReadableStream({
            start(controller) {
              return pump();
              function pump() {
                return reader.read().then(({done, value}) => {
                  if (done) {
                    controller.close();
                    return;
                  }
                  controller.enqueue(value);
                  return pump();
                });
              }
            }
          });
        }).then(stream => new Response(stream)).then(response => response.blob()).then(blob => URL.createObjectURL(blob)).then(url => resolve(url)).catch(function (x) {
          console.log(x);
          that.$root.U.hideLoader();
        });
      });
    }
  },
  id: 'e9dc1dc87c',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n    <div class="page" data-name="invdetail">\r\n\r\n      <!-- Page content -->\r\n      <div class="page-content">\r\n\r\n        ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.FORM_DATA, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n        <div class="navbar navbarBIG no-hairline">\r\n            <div class="navbar-bg"></div>\r\n            <div class="navbar-inner sliding">\r\n                ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.isInFullMasterDetail()', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                <div class="left bTit">\r\n                    <a href="#" class="link back">\r\n                        <i class="icon icon-back"></i>\r\n                        <span class="if-not-md mback">Volver</span>\r\n                    </a>&nbsp;';
              r += c(ctx_3.FORM_DATA.num, ctx_3);
              r += '                  \r\n                </div>\r\n                ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                <div class="left">\r\n                    <a href="#" class="link back">\r\n                        <i class="icon icon-back"></i>\r\n                        <span class="if-not-md mback">Volver</span>\r\n                    </a>\r\n                </div>\r\n                <div class="title bTit">';
              r += c(ctx_3.FORM_DATA.num, ctx_3);
              r += '</div>\r\n                ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                <div class="right">\r\n                    ';
          r += Template7Helpers.js_if.call(ctx_2, 'this._ID != -1', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                    ';
              r += Template7Helpers.if.call(ctx_3, ctx_3._INIT_BUY, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n                    ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n                        <a href="#" @click="btnDeleteRecord" class="link icon-only color-gray" title="';
                  r += Template7Helpers.T.call(ctx_4, 'delRecord', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '">\r\n                            <i class="f7-icons colorMain">trash</i>\r\n                        </a>\r\n                    ';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\r\n                    ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                </div>\r\n            </div>\r\n        </div>\r\n\r\n        <div class="card fullpage fixHF trueShadow">\r\n            <div class="card-content card-content-padding">\r\n\r\n                <div class="Istatus isPART">\r\n                ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.FORM_DATA.buy, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                    <div class="row">\r\n                        <div class="col-100 medium-30">\r\n                            <div class="ML">\r\n                                <img src="static/img/bggreen4.svg" width="160" height="160"/>\r\n                            </div>\r\n                        </div>\r\n                        <div class="col-100 medium-70">\r\n                            <div class="MR">\r\n                                Esta factura está asegurada.<br>\r\n                                <hr class="hrsep"/><br>\r\n                                <table align="center">\r\n                                    <tr><td class="tdr">Contratación:</td><td class="tdl">';
              r += Template7Helpers.D2.call(ctx_3, ctx_3.FORM_DATA.c, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '</td></tr>\r\n                                    ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.FORM_DATA.buySTAT === "ok"', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n                                        <tr><td class="tdr">Póliza número: </td><td class="tdl">LS19 - ';
                  r += c(ctx_4.FORM_DATA.buyLISA, ctx_4);
                  r += '</td></tr>\r\n                                        <tr><td class="tdr">Documento PDF:</td><td class="tdl"><a @click="downloadPDF" href="javascript:void(0);" class="link">Descargar</a></td></tr>\r\n                                    ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n                                        <tr><td class="tdr">Póliza número:</td><td class="tdl">Generando póliza...</td></tr>\r\n                                        <tr><td class="tdr">Estado:</td><td class="tdl">Generando póliza...</td></tr>\r\n                                    ';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\r\n                                    </table>\r\n                                <br>\r\n                            </div>    \r\n                        </div>\r\n                    </div>\r\n                ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                    <div class="row buy_no">\r\n                        <div class="col-100 medium-30">\r\n                            <div class="ML">\r\n                                <img src="static/img/alert.gif" width="55" height="50"/><br>\r\n                                Factura <br>no asegurada\r\n                            </div>\r\n                        </div>\r\n                        <div class="col-100 medium-70">\r\n                            <div class="MR">\r\n                                Esta factura está pendiente de ser asegurada.<br>\r\n                                El coste de asegurarla es de tan solo ';
              r += Template7Helpers.F2.call(ctx_3, ctx_3.totalPrice, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\u20AC<br>\r\n                                <hr class="hrsep"/>\r\n                                <div class="miniC">\r\n                                    Puede consultar las condiciones generales de contratación del <a href="/ipidmodal/" class="link">seguro aquí.</a><br>\r\n                                </div>\r\n                                <div class="miniC miniOK">\r\n                                    <ul class="miniC2 list">\r\n                                    <label class="ios item-checkbox item-content" style="padding:0">\r\n                                        <input type="checkbox" id="acceptOK"/>\r\n                                        <i class="icon icon-checkbox" style="margin-right: .7em;"></i>\r\n                                        <div class="item-inner">\r\n                                        <div class="item-text itx miniC2">Acepto los términos y condiciones <br>de la propuesta previa de seguro.</div>\r\n                                        </div>\r\n                                    </label>\r\n                                    </ul>\r\n                                </div>\r\n                                <br>\r\n                                <div @click="buyItem" class="button button-large button-raised button-fill minBTN">Comprar Seguro</div>\r\n                            </div>    \r\n                        </div>\r\n                    </div>\r\n                ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                </div>                \r\n\r\n                    <form class="list o1 ';
          r += Template7Helpers.if.call(ctx_2, ctx_2._INIT_BUY, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'xPEN';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '">\r\n                        <ul>\r\n\r\n                            <div class="row no-gap">\r\n                                <div class="col-100 medium-50">\r\n        \r\n                                    <fieldset class="LLL"><legend>Tomador del Seguro</legend>\r\n                                    <!-- cia  -->\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">Razón Social </div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="text" name="rs"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="Razón Social o Nombre y Apellidos"\r\n                                            xdata-error-message="ERmpty"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.rs, ctx_2);
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">DNI/CIF</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="text" name="cifT"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="DNI/CIF"\r\n                                            xdata-error-message="ERmpty"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.cifT, ctx_2);
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">Dirección</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="text" name="dir"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="Dirección"\r\n                                            xdata-error-message="ERmpty"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.dir, ctx_2);
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">Población</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="text" name="pob"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="Población"\r\n                                            xdata-error-message="ERmpty"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.pob, ctx_2);
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">Código Postal</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="text" name="cp"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="Código Postal"\r\n                                            xdata-error-message="ERmpty"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.cp, ctx_2);
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">Provincia</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="text" name="prov"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="Provincia"\r\n                                            xdata-error-message="ERmpty"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.prov, ctx_2);
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">Teléfono</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="text" name="tfno"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="Teléfono"\r\n                                            xdata-error-message="ERmpty"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.tfno, ctx_2);
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n                                    </fieldset>\r\n                                    <br><br>\r\n\r\n\r\n                                </div><div class="col-100 medium-50">\r\n    \r\n                                    <fieldset><legend>Datos de la Factura</legend>\r\n                                    <!-- fecha -->\r\n                                    <li class="item-content item-input item-input-outline item-input-with-value">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">Fecha de Factura</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="date" name="dat"\r\n                                            ';
          r += Template7Helpers.if.call(ctx_2, ctx_2._INIT_BUY, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                                            ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                                            min="';
              r += c(ctx_3.MIN_DATE, ctx_3);
              r += '" max="';
              r += c(ctx_3.MAX_DATE, ctx_3);
              r += '"\r\n                                            ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="Fecha"\r\n                                            xdata-error-message="ERmpty3"\r\n                                            autocomplete="off"\r\n                                            value="';
          r += Template7Helpers.js_if.call(ctx_2, 'this._ID != -1', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += c(ctx_3.FORM_DATA.dat, ctx_3);
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += c(ctx_3.ACT_DATE, ctx_3);
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">Número de Factura</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="text" name="num"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="Nº factura"\r\n                                            xdata-error-message="ERmpty"\r\n                                            autocomplete="off"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.num, ctx_2);
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n\r\n                                    <!-- importe -->\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">Importe total</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="number" step="any" min="1" max="6000.99" name="imp"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="Con IVA e impuestos incluido"\r\n                                            xdata-error-message="ERmpty2"\r\n                                            autocomplete="off"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.imp, ctx_2);
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n\r\n    \r\n                                    <!-- cif -->\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">DNI/CIF destinatario</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="text" name="cif"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="ID destinatario"\r\n                                            xdata-error-message="ERmpty"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.cif, ctx_2);
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n                                    </fieldset>\r\n\r\n                                </div>\r\n                            </div>  \r\n\r\n                            <input class="xhid" type="checkbox" name="_ENABLED" checked/>                          \r\n                    \r\n                        </ul>\r\n                    </form>\r\n\r\n            </div>\r\n            <div class="card-footer xtoolbar display-none">\r\n                <div>\r\n                    <button @click="btnCancel" class="button button-large button-raised button-fill color-gray minBTN">';
          r += Template7Helpers.T.call(ctx_2, 'cancel', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</button>              \r\n                </div>\r\n                <div>\r\n                    <button @click="btnUpdate" id="btnREGOK" class="button button-large button-raised button-fill minBTN">';
          r += Template7Helpers.T.call(ctx_2, 'update', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</button>\r\n                </div>\r\n            </div>\r\n        ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n            NO\r\n        ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '                \r\n        </div>\r\n\r\n      </div>\r\n    </div>\r\n';
      return r;
    }(this);
  },
  styleScoped: false
};
    