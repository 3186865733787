import HomePage from '../pages/home.f7.html';
import Login from '../pages/login.f7.html';
import Login0 from '../pages/login0.f7.html';
import LeftPageEmpty from '../pages/left-panel-empty.f7.html';
import LeftPanel from '../pages/left-panel.f7.html';
import LeftPanelPartner from '../pages/left-panel-partner.f7.html';
import PageHomeSummary from '../pages/page-home-summary.f7.html';
import PageHomeSummary_Partner from '../pages/page-home-summary-partner.f7.html';
import PageInvoiceM from '../pages/page-invoice-master.f7.html';
import PageInvoiceD from '../pages/page-invoice-detail.f7.html';
import PageInvoiceM_Partner from '../pages/page-invoice-master-partner.f7.html';
import PageInvoiceD_Partner from '../pages/page-invoice-detail-partner.f7.html';
import PageProfile from '../pages/page-profile.f7.html';
import PageInci from '../pages/page-inci.f7.html';
import PageHelp from '../pages/page-help.f7.html';
import PageLegal from '../pages/page-legal.f7.html';
import PagePayment from '../pages/page-payment.f7.html';
import PageWHook from '../pages/page-whook.f7.html';
import PageIPID from '../pages/page-ipid.f7.html';
import PageIPIDmodal from '../pages/page-ipid-modal.f7.html';

import FB from '../js/FB.js';

/*
function checkAuth(to, from, resolve, reject) {
  //TODO
  console.log(to);
  if (FB.userLogged) {
    resolve({ url: 'secured.html' });
  } else {
    resolve({ url: 'x.html' });
    //reject();
  }
}
function checkPermission(to, from, resolve, reject) {
  //TODO
  if (1) {
    resolve();
  } else {
    reject();
  }
}
*/

var routes = [
  {
    path: '/login0/',
    component: Login0,
    options: { 
      reloadAll: true,
    }      
  },
  {
    path: '/login/',
    component: Login,
    options: { 
      reloadAll: true,
    }      
  },
  {
    path: '/',
    component: HomePage,
    //beforeEnter: [checkAuth, checkPermission],
    keepAlive: true,
  },
  {
    path: '/leftPanel/',
    component: LeftPanel,
  },
  {
    path: '/leftPanel_Partner/',
    component: LeftPanelPartner,
  },
  {
    path: '/leftPanelEmpty/',
    component: LeftPageEmpty,
  },

  {path: '/hosu/',
    component: PageHomeSummary,
    //keepAlive: true,
    options: { 
      reloadAll: true,
      //history: false
    }       
  },
  {path: '/hosuPartner/',
    component: PageHomeSummary_Partner,
    //keepAlive: true,
    options: { 
      reloadAll: true,
      //history: false
    }       
  },
  {path: '/profile/',
    component: PageProfile,
    //keepAlive: true,
    options: { 
      reloadAll: true,
      //history: false
    }       
  },
  {path: '/inci/',
    component: PageInci,
    //keepAlive: true,
    options: { 
      reloadAll: true,
      //history: false
    }       
  },
  {path: '/ipid/',
    component: PageIPID,
    //keepAlive: true,
    options: { 
      reloadAll: true,
      //history: false
    }       
  },
  {path: '/ipidmodal/',
    popup:{
      component: PageIPIDmodal
    }
  },
  {path: '/help/',
    component: PageHelp,
    //keepAlive: true,
    options: { 
      reloadAll: true,
      //history: false
    }       
  },
  {path: '/legal/',
    component: PageLegal,
    //keepAlive: true,
    options: { 
      reloadAll: true,
      //history: false
    }       
  },
  {path: '/WHK/',
    component: PageWHook,
    //keepAlive: true,
    options: { 
      reloadAll: true,
      //history: false
    }       
  },
  {path: '/paym/:id/',
    component: PagePayment,
    keepAlive: true,
  },


  {path: '/invoices/',
    component: PageInvoiceM,
    master: true,
    //options: {clearPreviousHistory:true},
    detailRoutes: [{
        path: '/invoices/invoiceDetail/:id/',
        component: PageInvoiceD,
        //options: {clearPreviousHistory:true},
      }
    ]},  

  {path: '/invoicesPartner/',
    component: PageInvoiceM_Partner,
    master: true,
    //options: {clearPreviousHistory:true},
    detailRoutes: [{
        path: '/invoicesPartner/invoiceDetail/:id/',
        component: PageInvoiceD_Partner,
        //options: {clearPreviousHistory:true},
      }
    ]},  


  /*
  {
    path: '(.*)',
    component: NotFoundPage,
  },
  */
];

export default routes;