//import Dom7 from 'framework7/framework7.esm.bundle.js';
//import $ from '../lib/jquery-3.4.0.min.js';
import $$ from 'dom7';
import M from '../js/i18n.js';
import translate from 'translate.js';
import merge from 'deepmerge'; 

var U = {};
//default english
var i18n = translate(M.en);

//console.log
U.cc = '';
U.L = console.log.bind(window.console);
U.E = console.log.bind(window.console, '%c[E] %s ', 'background:red; color:white;');

/*
U.T = function(s){
    //console.log(s);
    console.log('%c [T] '+s, 'color: rgba(0,200,200)');
};
*/

U.T0 = function(){};
U.T = U.T0;
U.L0 = function(){};
U.L = U.L0;

U.isTraceON = function(d){
    if (d){
        U.T0 = console.log.bind(window.console, '%c[T] %s ', 'background:green; color:white;');
        U.L0 = console.log.bind(window.console, '%c[L] %s ', 'background:black; color:white;');
    } else{
        U.T0 = function(){};
        U.L0 = function(){};
    }
    U.T = U.T0;
    U.L = U.L0;
};



/*
---->
http://tobyho.com/2012/07/27/taking-over-console-log/
args = Array.prototype.slice.call(arguments, 0).concat(['\t\t\t@'+_line]);
window.console.log.apply(window.console, args);


var logOfConsole = [];

var _log = console.log,
    _warn = console.warn,
    _error = console.error;

console.log = function() {
    logOfConsole.push({method: 'log', arguments: arguments});
    return _log.apply(console, arguments);    
};

console.warn = function() {
    logOfConsole.push({method: 'warn', arguments: arguments});
    return _warn.apply(console, arguments);
};

console.error = function() {
    logOfConsole.push({method: 'error', arguments: arguments});
    return _error.apply(console, arguments);
};
*/

//Global Loader
U.showLoader = function(cls){
    //if (t) $$("#aloader").addClass("transp");
    if (cls) $$("#gloader").addClass(cls);
    $$("#gloader").show();
    U.LDR = true;
};
U.hideLoader = function(cls){
    if (cls) $$("#gloader").removeClass(cls);
    $$("#gloader").hide();
    //$$("#aloader").removeClass("transp");
    U.LDR = false;
};

//Notif
U.showToast = function(isOK, msg, title, subtitle){
    var xapp = U.getF7();
    if (!xapp) return;
    var xnot = xapp.notification.create({
        closeTimeout: isOK ? 3500 : 5000,
        //closeOnClick: true,
        title: msg,
        //text: msg,
        //subtitle: subtitle,
        cssClass: isOK ? 'toast-green' : 'toast-red',
        icon: isOK ? '<i class="f7-icons colorIcon">checkmark_alt_circle</i>' : '<i class="f7-icons colorIcon">xmark_circle</i>',
        on: {
            close: function () {
                xnot.$el.remove();
            }
        }
    }).open();
};

U.showErrorWindow = function(msg, callback){
    U.getF7().$('BODY').addClass('DIALOG_CUSTOM_SHOWN DCSERR');
    var icon = '<i class="f7-icons iconErrL">multiply_circle_fill</i>';
    U.getF7().dialog.alert(icon+msg+'<br><br>', undefined, function(){
        setTimeout(function(){
            U.getF7().$('BODY').removeClass('DIALOG_CUSTOM_SHOWN DCSERR');
        },360);
        if (callback) callback();
    });
};
U.showSuccessWindow = function(msg, callback){
    U.getF7().$('BODY').addClass('DIALOG_CUSTOM_SHOWN');
    var icon = '<i class="f7-icons iconErrL">checkmark_alt_circle</i>';
    U.getF7().dialog.alert(icon+msg+'<br><br>', undefined, function(){
        setTimeout(function(){
            U.getF7().$('BODY').removeClass('DIALOG_CUSTOM_SHOWN');
        },360);

        if (callback) callback();
    });
};

//INPUT TYPES
U.composeInputs = function(ctx){
    ctx.find('INPUT[xfdata]').each(function(i) {
    var o = $$(this);
      var P = o.attr('xplaceholder');
      if (P) {
        o.attr('placeholder', U.getL(P));
      }
      P = o.attr('xdata-error-message');
      if (P) {
        o.attr('data-error-message', U.getL(P));
      }
    });
    //readonly -> NoPointerEvents
    ctx.find('input, select, textarea').each((inputIndex, inputEl) => {
        if (inputEl.hasAttribute('readonly')){
            inputEl.parentNode.classList.add('xPEN');
        }
    });

};


//LANG
U.setLang = function(l){
    //i18n = translate(l);
    l = l || U.getSystemLang();
    i18n = translate(M[l]);
    var F7 = U.getF7();
    if (F7){
        F7.params.dialog.buttonOk = U.getL('btnOk');
        F7.params.dialog.buttonCancel = U.getL('btnCancel');
        F7.data.FB.changeTopUserName();
    }
};
U.getL = function(txt){
    return i18n(txt);
};
U.getSystemLang = function(){
    var res = 'en';
    try{
        if (window.__FORCEDLANG) return window.__FORCEDLANG;
        res = navigator.language.split('-')[0];
        if (res != 'en' && res != 'es') res = 'en';
        return res;
    }catch(e){
        console.log(e);
        return 'en';
    }
};

//app F7 ref
U.setF7 = function(app){
    this.appF7 = app;
};
U.getF7 = function(app){
    return this.appF7;
};


//HELPERS
U.mergeDeep = function(x, y, opt){
    return merge(x, y, opt);
};
U.cloneO = function(o){
    if (Array.isArray(o)){
        return o.slice(0);
    }
    return Object.assign({}, o);
};

U.objectDiff = function(obj1, obj2){
    var res = '';
    try{
        res = Object.keys(obj1).filter(k => obj1[k] !== obj2[k]);
    }catch(e){console.log(e);}
    return res;
};

U.arrayDiff = function(arr1, arr2){
    arr1 = arr1 || [];
    arr2 = arr2 || [];
    var filteredArr1 = arr1.filter(function(ele) {
        return arr2.indexOf(ele) == -1;
      });
    
    var filteredArr2 = arr2.filter(function(ele) {
        return arr1.indexOf(ele) == -1;
    });
    return filteredArr1.concat(filteredArr2);
};

U.emptyPromise = function(o){
    return new Promise(function(resolve, reject) {
        resolve(o || false);
    });	
};

U.isInDesktopMasterDetail = function() {
    return U.getF7().$('.view-main').hasClass('view-master-detail');
};

/*
U.scrolltoLastLI = function(e){
    var _whole = e;
    if (typeof e === String){
        _whole = U.getF7().$(e);
    }
    var _el = _whole[_whole.length-1];
    _el.scrollIntoView();
    //$('#dates').scrollTop($('#dates')[0].scrollHeight);
};
*/
U.scrolltoLastLI = function(o){
    var _el = o.find('LI.selItem');
    if (_el.length) _el[0].scrollIntoView();
};

U.A2B = function(txt){
    return txt ? window.btoa(txt).split("").reverse().join("") : '';
};

U.B2A = function(txt){
    return txt ? window.atob(txt.split("").reverse().join("")) : '';
};

U.Json2Array = function(obj, prefix){
    var res = {};
    for (var k of Object.keys(obj)) {
        var val = obj[k],
            key = prefix ? prefix + '/' + k : k;
        if (typeof val === 'object')
            Object.assign(res, U.Json2Array(val, key)); 
        else
            res[key] = val;
    }
    return res;
};

U.sortJSONdates = function(o, field, asc){
    if (!o || Object.keys(o).length==0) return o;
    var O2 = {};
    Object
        .keys(o)
        .sort(function(a,b){
                return asc ? o[a][field]-o[b][field] : o[b][field]-o[a][field];
            })
        .map(function(i){ O2[i] = o[i]; });
    return O2;
};

//sortJSONdates w/ CLOSED
U.sortJSONdatesCLS = function(o, field, asc, includeCLOSED, chkField){
    if (!o || Object.keys(o).length==0) return o;
    var O2 = {};
    chkField = chkField || '_CLOSED';   //default
    Object
        .keys(o)
        .filter(function(x){return includeCLOSED ? true : !o[x][chkField]})
        .sort(function(a,b){
                return asc ? o[a][field]-o[b][field] : o[b][field]-o[a][field];
            })
        .map(function(i){ O2[i] = o[i]; });
    return O2;
};

U.addDays = function(date, days) {
    //only 1 param? -> it's ONLY days
    if (!days) {
        days = date;
        date = new Date();
    }
    date.setDate(date.getDate() + days);
    return date;
};




/*
U.sortJSONdatesMETADATA = function(o, field, asc){
    var O2 = {};
    Object
        .keys(o)
        .sort(function(a,b){
                return asc ? o[a]['_METADATA'][field]-o[b]['_METADATA'][field] : o[b]['_METADATA'][field]-o[a]['_METADATA'][field];
            })
        .map(function(i){ O2[i] = o[i]; });
    return O2;
};
*/

U.sortJSON = function(o, field, desc){
    var O2 = {};
    var x = Object.keys(o).map(function(i){return [ o[i][field], o[i], i ]});
    if (desc) x.reverse();
    x.sort().map(function(i){ O2[i[2]] = o[i[2]]; });
    return O2;
};

U.shortDateFormat = function(d, inclTime, f){
    if (!d) return '';
    var res = '';
    try{
        if (typeof d === 'number') d = new Date(d);
        res = d.toLocaleString(navigator.language, {weekday:f||'short', month:f||'short', day:'numeric' , year:'numeric'});
        if (inclTime) res = d.toLocaleString(navigator.language, {weekday:f||'short', month:f||'short', day:'numeric' , year:'numeric', hour:'numeric', minute:'2-digit'});
    }catch(e){console.log(e);}
    return res;
};
/*
U.longDateFormat = function(d, inclTime, f){
    if (!d) return '';
    var res = '';
    try{
        if (typeof d === 'number') d = new Date(d);
        res = d.toLocaleString(navigator.language, {month:f||'long', day:'numeric' , year:'numeric'});
        if (inclTime) res = d.toLocaleString(navigator.language, {month:f||'long', day:'numeric' , year:'numeric', hour:'numeric', minute:'2-digit'});
    }catch(e){console.log(e);}
    return res;
};
*/
U.longDateFormat_ATF = function(d, inclTime){
    if (!d) return '';
    var res = '--';
    try{
        d = new Date(d);
        //res = d.toLocaleString(navigator.language, {month:'long', day:'numeric' , year:'numeric'});
        res = d.getDate() + ' ' + U.getL('MONTHS').split(',')[d.getMonth()].trim() + ', ' + d.getFullYear();
    }catch(e){console.log(e);}
    return res;
};

U.microDateFormat = function(d, noTime){
    if (!d) return '';
    var res = '';
    try{
        if (typeof d === 'number') d = new Date(d);
        res = d.toLocaleDateString(navigator.language, {month:'short', day:'numeric' , hour:'numeric', minute:'2-digit'})
        if (noTime) res = d.toLocaleDateString(navigator.language, {month:'short', day:'numeric'})
    }catch(e){console.log(e);}
    return res;
};

U.nanoDateFormat = function(d){
    if (!d) return '';
    var res = '';
    try{
        if (typeof d === 'number') d = new Date(d);
        res = d.toLocaleDateString(navigator.language, {month:'2-digit', day:'2-digit'})
    }catch(e){console.log(e);}
    return res;
};


U.currencyFormat = function(n, inclSymbol, noSpace){
    var sp = noSpace ? '' : ' ';
    var res = '';
    try{
        res = n.toLocaleString(
            undefined, // leave undefined to use the browser's locale,
                       // or use a string like 'en-US' to override it.
            { minimumFractionDigits: 2 }
          );    
    }catch(e){console.log(e)}
    return res + sp + (inclSymbol ? U.getF7().data.FB.GLOBALCONFIG2.currencySymbol : '');
};

U.currencyFormatZero = function(n){
    var res = '';
    try{
        res = n.toLocaleString(
            undefined, // leave undefined to use the browser's locale,
                       // or use a string like 'en-US' to override it.
            { maximumFractionDigits: 0 }
          );    
    }catch(e){console.log(e)}
    return res + U.getF7().data.FB.GLOBALCONFIG2.currencySymbol;
};

U.openNewWin = function(u){
    if (u){
        var win = window.open(u, '_blank');
        win.focus();                        
    }
};

U.linkify = function(text) {
    var urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return text.replace(urlRegex, function(url) {
        return '<a class="external" target="_blank" href="' + url + '"><img class="lnk" src="static/img/lnk.svg">' + url + '</a>';
    });
};

U.removeArr = function(arr) {
    var what, a = arguments, L = a.length, ax;
    while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax= arr.indexOf(what)) !== -1) {
            arr.splice(ax, 1);
        }
    }
    return arr;
};

U.b64d = function(str) {
	if (!window.atob) return '';
    return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''));
};

U.getActualRoute = function(){
    return U.getF7().view.main.router.currentRoute.url;
};

U.importJS = function(file, callback, forced){
    if (!U.__IMPORTED) U.__IMPORTED = [];
    //if (U.__IMPORTED.indexOf(file)>-1 && !forced) {
    if (U.__IMPORTED.indexOf(file)>-1) {
        console.log('file cached')
        if (callback) callback();
        return;
    }
    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.onload = function() {
        if (callback) callback();
    };
    script.src = file;
    U.__IMPORTED.push(file);
    head.appendChild(script);    
};

//https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
U.browser = function(){
    var res = {};
    res.isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    res.isFirefox = typeof InstallTrigger !== 'undefined';
    res.isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
    res.isIE = /*@cc_on!@*/false || !!document.documentMode;
    res.isEdge = !res.isIE && !!window.StyleMedia;
    res.isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    res.isEdgeChromium = res.isChrome && (navigator.userAgent.indexOf("Edg") != -1);
    res.isBlink = (res.isChrome || res.isOpera) && !!window.CSS;    
    return res;
};

U.getSubdomain = function(){
    var res = '';
    var url = window.location.hostname.split('.');
    if (url.length == 3) res = url[0];
    //dev only
    if (res == '' || res=='enterprise') return 'ACME2';
    return res.toLowerCase();
};

U.injectCSS = function(css){
    var node = document.createElement('style');
    node.innerHTML = css;
    document.body.appendChild(node);
};

export default U;