
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import myMixin from './mixin-master-detail.js';
export default {
  mixins: [myMixin],
  data: function () {
    return {
      _DB_TABLE: 'ciadata',
      _CACHE_TABLE: 'CIADATA',
      _MASTER_PAGE: null,
      _MASTER_EVENT: 'nullsettings',
      DATA_LOADED: false
    };
  },
  on: {
    pageAfterIn() {
      var that = this;
      this.$app.emit('selectMenu', '#m_prof');
    },
    pageInit(e, page) {
      this._ID = 'CIADATA';
      this._URL_CACHE = this.$root.FB.CACHE[this._CACHE_TABLE];
      if (this._URL_CACHE === null)
        this._URL_CACHE = {};
      this.FORM_DATA_ORIG = this._URL_CACHE[this._ID];
      this._UPDATE_DB_PATH = '/' + this._DB_TABLE + '/' + this.$root.FB.getUID() + '/' + this._ID;
    },
    pageBeforeIn: function (e, page) {
      this.getInitData();
      this.checkGlobal();
    }
  },
  methods: {
    cookData4DB(_F) {
      _F.cif = _F.cif.toUpperCase();
      return _F;
    },
    formChangedG() {
      this.formChanged();
      this.checkGlobal();
    },
    checkGlobal() {
      if (this.$el.find('INPUT[name="_ALL_DISABLED"]').prop('checked')) {
        this.$el.find('.bl01').addClass('xPENhid');
      } else {
        this.$el.find('.bl01').removeClass('xPENhid');
      }
    },
    showToolbar() {
      this.checkGlobal();
      this.$el.find('.xtoolbar').removeClass('display-none');
    },
    hideToolbar() {
      this.checkGlobal();
      this.$el.find('.xtoolbar').addClass('display-none');
    },
    pageFullyLoaded() {
      this.checkGlobal();
    }
  },
  id: '67d01c684e',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n\t\t<div class="page" data-name="profile">\r\n\r\n            <!--\r\n\t\t\t\t<div class="navbar navbarBIG no-hairline">\r\n\t\t\t\t\t<div class="navbar-bg"></div>\r\n\t\t\t\t\t<div class="navbar-inner sliding">\r\n\t\t\t\t\t\t<div class="left bTit">&nbsp;';
      r += Template7Helpers.T.call(ctx_1, 'hosu', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\r\n\t\t\t\t\t\t<div class="right">\r\n\t\t\t\t\t\t</div>\r\n\t\t\t\t\t</div>\r\n                </div>\r\n            -->\r\n                <!--<img class="mpp" src="static/img/profile.gif"/>-->\r\n\t\t\t\t<div class="page-content margin">\r\n                    <div class="xmtit mtitS bTit">Mi Perfil</div>\r\n\r\n                    ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.FORM_DATA, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                    <div class="card fullpage fixHF trueShadow">\r\n                        <div class="card-content card-content-padding">\r\n            \r\n                            <form class="list o1" autocomplete="off">\r\n                                <ul>\r\n\r\n                                <div class="row no-gap">\r\n                                    <div class="col-100 medium-33">\r\n\r\n                                        <li class="item-content item-input item-input-outline item-input-with-value">\r\n                                            <div class="item-inner">\r\n                                            <div class="item-title item-floating-label">Nombre</div>\r\n                                            <div class="item-input-wrap">\r\n                                                <input type="text" name="name"\r\n                                                @input="formChanged"\r\n                                                validate xfdata required\r\n                                                xplaceholder="Su nombre"\r\n                                                xdata-error-message="ERmpty"\r\n                                                value="';
          r += c(ctx_2.FORM_DATA.name, ctx_2);
          r += '"/>       \r\n                                            </div>\r\n                                            <div class="small">';
          r += Template7Helpers.T.call(ctx_2, 'Gmul', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                            </div>\r\n                                        </li>\r\n    \r\n                                    </div><div class="col-100 medium-33">\r\n\r\n                                        <li class="item-content item-input item-input-outline item-input-with-value">\r\n                                            <div class="item-inner">\r\n                                            <div class="item-title item-floating-label">Apellidos</div>\r\n                                            <div class="item-input-wrap">\r\n                                                <input type="text" name="apell"\r\n                                                @input="formChanged"\r\n                                                validate xfdata required\r\n                                                xplaceholder="Apellidos completos"\r\n                                                xdata-error-message="ERmpty"\r\n                                                value="';
          r += c(ctx_2.FORM_DATA.apell, ctx_2);
          r += '"/>       \r\n                                            </div>\r\n                                            <div class="small">';
          r += Template7Helpers.T.call(ctx_2, 'Gmul', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                            </div>\r\n                                        </li>\r\n\r\n                                    </div><div class="col-100 medium-33">\r\n\r\n                                        <li class="item-content item-input item-input-outline item-input-with-value">\r\n                                            <div class="item-inner">\r\n                                            <div class="item-title item-floating-label">Teléfono</div>\r\n                                            <div class="item-input-wrap">\r\n                                                <input type="text" name="tfno"\r\n                                                @input="formChanged"\r\n                                                validate xfdata required\r\n                                                xplaceholder="Teléfono de Contacto"\r\n                                                xdata-error-message="ERmpty"\r\n                                                value="';
          r += c(ctx_2.FORM_DATA.tfno, ctx_2);
          r += '"/>       \r\n                                            </div>\r\n                                            <div class="small">';
          r += Template7Helpers.T.call(ctx_2, 'Gmul', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                            </div>\r\n                                        </li>\r\n\r\n                                    </div>\r\n                                </div>\r\n                                                                  \r\n                                <div class="row no-gap">\r\n                                    <div class="col-100 medium-50">\r\n\r\n                                        <li class="item-content item-input item-input-outline item-input-with-value">\r\n                                            <div class="item-inner">\r\n                                            <div class="item-title item-floating-label">Razón social</div>\r\n                                            <div class="item-input-wrap">\r\n                                                <input type="text" name="rs"\r\n                                                @input="formChanged"\r\n                                                validate xfdata \r\n                                                xplaceholder="Razón social"\r\n                                                xdata-error-message="ERmpty"\r\n                                                value="';
          r += c(ctx_2.FORM_DATA.rs, ctx_2);
          r += '"/>       \r\n                                            </div>\r\n                                            <div class="small">';
          r += Template7Helpers.T.call(ctx_2, 'Gmul', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                            </div>\r\n                                        </li>\r\n    \r\n                                        <!--\r\n                                        <hr class="margin opa2">\r\n                                        TBD Resto de campos necesarios... \r\n                                        -->\r\n                                    </div><div class="col-100 medium-50">\r\n\r\n                                        <li class="item-content item-input item-input-outline item-input-with-value">\r\n                                            <div class="item-inner">\r\n                                            <div class="item-title item-floating-label">CIF / DNI</div>\r\n                                            <div class="item-input-wrap">\r\n                                                <input type="text" name="cif"\r\n                                                @input="formChanged"\r\n                                                pattern="([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])"\r\n                                                validate xfdata required\r\n                                                xplaceholder="CIF, DNI, NIE, TIE"\r\n                                                xdata-error-message="cifempty"\r\n                                                value="';
          r += c(ctx_2.FORM_DATA.cif, ctx_2);
          r += '"/>       \r\n                                            </div>\r\n                                            <div class="small">';
          r += Template7Helpers.T.call(ctx_2, 'Gmul', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                            </div>\r\n                                        </li>\r\n\r\n                                    </div>\r\n                                </div>\r\n\r\n                                <div class="row no-gap">\r\n                                    <div class="col-100 medium-50">\r\n\r\n                                        <li class="item-content item-input item-input-outline item-input-with-value">\r\n                                            <div class="item-inner">\r\n                                            <div class="item-title item-floating-label">Dirección</div>\r\n                                            <div class="item-input-wrap">\r\n                                                <input type="text" name="dir"\r\n                                                @input="formChanged"\r\n                                                validate xfdata required\r\n                                                xplaceholder="Dirección"\r\n                                                xdata-error-message="ERmpty"\r\n                                                value="';
          r += c(ctx_2.FORM_DATA.dir, ctx_2);
          r += '"/>       \r\n                                            </div>\r\n                                            <div class="small">';
          r += Template7Helpers.T.call(ctx_2, 'Gmul', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                            </div>\r\n                                        </li>\r\n                                        <!--\r\n                                        <hr class="margin opa2">\r\n                                        TBD Resto de campos necesarios... \r\n                                        -->\r\n\r\n                                    </div><div class="col-100 medium-50">\r\n\r\n                                        <li class="item-content item-input item-input-outline item-input-with-value">\r\n                                            <div class="item-inner">\r\n                                            <div class="item-title item-floating-label">Población</div>\r\n                                            <div class="item-input-wrap">\r\n                                                <input type="text" name="pob"\r\n                                                @input="formChanged"\r\n                                                validate xfdata required\r\n                                                xplaceholder="Población"\r\n                                                xdata-error-message="ERmpty"\r\n                                                value="';
          r += c(ctx_2.FORM_DATA.pob, ctx_2);
          r += '"/>       \r\n                                            </div>\r\n                                            <div class="small">';
          r += Template7Helpers.T.call(ctx_2, 'Gmul', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                            </div>\r\n                                        </li>\r\n\r\n                                    </div>\r\n                                </div>\r\n                                                                  \r\n                                <div class="row no-gap">\r\n                                    <div class="col-100 medium-50">\r\n\r\n                                        <li class="item-content item-input item-input-outline item-input-with-value">\r\n                                            <div class="item-inner">\r\n                                            <div class="item-title item-floating-label">Código Postal</div>\r\n                                            <div class="item-input-wrap">\r\n                                                <input type="text" name="cp"\r\n                                                xpattern="[0-9]{5}"\r\n                                                @input="formChanged"\r\n                                                validate xfdata required\r\n                                                xplaceholder="Código Postal"\r\n                                                xdata-error-message="ERmpty"\r\n                                                value="';
          r += c(ctx_2.FORM_DATA.cp, ctx_2);
          r += '"/>       \r\n                                            </div>\r\n                                            <div class="small">';
          r += Template7Helpers.T.call(ctx_2, 'Gmul', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                            </div>\r\n                                        </li>\r\n\r\n                                    </div><div class="col-100 medium-50">\r\n\r\n                                        <li class="item-content item-input item-input-outline item-input-with-value">\r\n                                            <div class="item-inner">\r\n                                            <div class="item-title item-floating-label">Provincia</div>\r\n                                            <div class="item-input-wrap">\r\n                                                <input type="text" name="prov"\r\n                                                @input="formChanged"\r\n                                                validate xfdata required\r\n                                                xplaceholder="Provincia"\r\n                                                xdata-error-message="ERmpty"\r\n                                                value="';
          r += c(ctx_2.FORM_DATA.prov, ctx_2);
          r += '"/>       \r\n                                            </div>\r\n                                            <div class="small">';
          r += Template7Helpers.T.call(ctx_2, 'Gmul', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                            </div>\r\n                                        </li>\r\n\r\n                                    </div>\r\n                                </div>\r\n\r\n                                <div class="row no-gap">\r\n                                    <div class="col-100 medium-50">\r\n\r\n                                        <li class="item-content item-input item-input-outline item-input-with-value">\r\n                                            <div class="item-inner">\r\n                                            <div class="item-title item-floating-label">País</div>\r\n                                            <div class="item-input-wrap">\r\n                                                <input type="text" name="icountry"\r\n                                                xpattern="[0-9]{5}"\r\n                                                @input="formChanged"\r\n                                                xfdata\r\n                                                xplaceholder="País"\r\n                                                xdata-error-message="ERmpty"\r\n                                                value="';
          r += c(ctx_2.FORM_DATA.icountry, ctx_2);
          r += '"/>       \r\n                                            </div>\r\n                                            <div class="small">';
          r += Template7Helpers.T.call(ctx_2, 'Gmul', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                            </div>\r\n                                        </li>\r\n\r\n                                    </div><div class="col-100 medium-50">\r\n\r\n                                    </div>\r\n                                </div>\r\n                                <input class="xhid" type="hidden" name="email" value="';
          r += c(ctx_2.$root.FB.auth.currentUser.email, ctx_2);
          r += '"/>\r\n                                <input class="xhid" type="hidden" name="org" value="';
          r += c(ctx_2.$root.FB.auth.currentUser.displayName, ctx_2);
          r += '"/>\r\n                                                                  \r\n                                </ul>\r\n                            </form>\r\n\r\n                        </div>\r\n                        <div class="card-footer xtoolbar display-none">\r\n                            <div>\r\n                                <button @click="btnCancel" class="button button-large button-raised button-fill color-gray minBTN">';
          r += Template7Helpers.T.call(ctx_2, 'cancel', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</button>              \r\n                            </div>\r\n                            <div>\r\n                                <button @click="btnUpdate" id="btnREGOK" class="button button-large button-raised button-fill minBTN">';
          r += Template7Helpers.T.call(ctx_2, 'update', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</button>\r\n                            </div>\r\n                        </div>\r\n                    </div>\r\n            \r\n                    ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n\r\n                </div>\r\n        </div>\r\n\r\n';
      return r;
    }(this);
  },
  style: `
[data-f7-67d01c684e] .item-input-error-message{
    font-family: var(--f7-font-familyB);
}
[data-f7-67d01c684e] .card.fullpage{
    height: calc(100% - var(--f7-appbar-height) - var( --f7-navbar-height) - 12px + var(--f7-navbar-inner-padding-right));
}

[data-f7-67d01c684e] .mpp{
    position: absolute;
    z-index: 9;
    height: 60px;
    top: -6px;
    right: 35px;        
}
[data-f7-67d01c684e] .mtit{
    font-size: 16px;
    font-family: var(--f7-font-family2);    
}
[data-f7-67d01c684e] .mtitS{
    margin-left: var(--f7-navbar-inner-padding-left);
    margin-bottom: 10px;
}
[data-f7-67d01c684e] .small{
    display: none;
    font-size: 12px;
    color: #aaa;
    }
[data-f7-67d01c684e] .inputLabel {
    min-width: 200px;
    display: inline-block;
}
[data-f7-67d01c684e] .rat00 LI{
    margin-bottom: 0;
}
[data-f7-67d01c684e] .opa2{
    opacity: .25;
}

`,
  styleScoped: true
};
    