
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      DATA_LOADED: false,
      CAL_LIST: [],
      TOTAL_WO_MONTH_EVENTS: 0,
      ONLY_SEL_DAY: false,
      TOTALS: {
        TASKS: 0,
        TASKS_CLOSED: 0,
        PROPOSALS: 0,
        PROPOSALS_CLOSED: 0,
        WO: 0,
        WO_CLOSED: 0
      },
      _WIPMARKERS: [],
      gmap: null,
      umap: null,
      partnerCODE: null
    };
  },
  on: {
    pageInit(e, page) {
    },
    pageAfterIn() {
      var that = this;
      this.$app.emit('selectMenu', '#m_hosu');
      this.$root.U.hideLoader();
      this.partnerCODE = this.$root.FB.userProfile ? this.$root.FB.userProfile.partnerCODE : 'OOO';
      this.$update();
    },
    pageBeforeIn() {
      if (this.$app.device.desktop) {
      }
    },
    pageAfterOut() {
    }
  },
  methods: {
    getDBdata() {
    },
    openMail: function () {
      this.$('.oM:hover .addr').css('display', 'block');
      var U = this.$root.U;
      var SP = '\n';
      var msg = '';
      msg += 'Bienvenido a Seguro-Bodas.com' + SP + SP;
      msg += 'Puede darse de alta desde este enlace:' + SP;
      msg += 'https://app.seguro-bodas.com/?p=' + this.partnerCODE + SP + SP;
      msg += 'Gracias.' + SP + SP;
      window.open('mailto:?subject=Seguro-Bodas&body=' + encodeURIComponent(msg), '_blank');
    },
    generatePieGraph(t, e) {
      var offsetX, offsetY;
      !function () {
        var t = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
        window.requestAnimationFrame = t;
      }();
      var o, i, n = e.hasOwnProperty('pie') ? e.pie : 'normal', r = e.values, a = e.colors, l = !!e.hasOwnProperty('animation') && e.animation, s = e.hasOwnProperty('animationSpeed') ? e.animationSpeed : 20, f = !!e.hasOwnProperty('fillTextData') && e.fillTextData, h = e.hasOwnProperty('fillTextColor') ? e.fillTextColor : '#fff', P = e.hasOwnProperty('fillTextPosition') ? e.fillTextPosition : 'horizontal', u = e.hasOwnProperty('fillTextAlign') ? e.fillTextAlign : 1.85, c = e.hasOwnProperty('doughnutHoleSize') ? e.doughnutHoleSize : null, d = e.hasOwnProperty('doughnutHoleColor') ? e.doughnutHoleColor : '#fff', m = e.hasOwnProperty('offset') ? e.offset : null, p = null, g = L(e, 'strokeStartEndPoints') ? e.isStrokePie.strokeStartEndPoints : 'No', v = !!L(e, 'overlayStroke') && e.isStrokePie.overlayStroke, k = L(e, 'overlayStrokeColor') ? e.isStrokePie.overlayStrokeColor : '#eee', M = !L(e, 'strokeAnimation') || e.isStrokePie.strokeAnimation, S = L(e, 'strokeAnimationSpeed') ? e.isStrokePie.strokeAnimationSpeed : 10, y = L(e, 'stroke') ? e.isStrokePie.stroke : 20, w = L(e, 'fontSize') ? e.isStrokePie.fontSize : '60px', I = L(e, 'textAlignement') ? e.isStrokePie.textAlignement : 'center', A = L(e, 'fontFamily') ? e.isStrokePie.fontFamily : 'Arial', T = L(e, 'fontWeight') ? e.isStrokePie.fontWeight : 'bold', x = document.getElementById(t), b = x.getContext('2d'), O = x.width / 2, C = x.height / 2, F = O, z = 0, q = [], H = (o = 0, r.map(t => {
          o += t;
        }), o), E = 0, R = 0;
      if (r.length > 1)
        for (var W = 0; W < r.length; W++) {
          var Y = 0 == W ? 0 : E;
          E = r[W] * (100 / H) + Y, q.push([
            Math.round(Y),
            Math.round(E),
            a[W]
          ]);
        }
      function j() {
        q.forEach(function (t, e) {
          var o = t[0] / 100 * Math.PI * 2, i = null != m ? (t[1] - m / 10) / 100 * Math.PI * 2 : t[1] / 100 * Math.PI * 2, n = ((o = 'Top' == p ? o - Math.PI / 2 : o) + (i = 'Top' == p ? i - Math.PI / 2 : i)) / 2;
          offsetX = 0 == m || null == m ? 0 : Math.cos(n) * m, offsetY = 0 == m || null == m ? 0 : Math.sin(n) * m, b.beginPath(), b.moveTo(O + offsetX, C + offsetY), b.arc(O + offsetX, C + offsetY, F, o, i), b.fillStyle = t[2], b.fill(), b.fillStyle = d, b.beginPath(), b.moveTo(O, C), b.arc(O, C, c / 100 * C, 0, 2 * Math.PI), b.closePath(), b.fill();
        });
      }
      function X(t) {
        return t / 100 * (2 * Math.PI);
      }
      function B(t, e, o, i, n, r, a) {
        b.beginPath(), b.arc(t, e, o, i, n, !1), b.strokeStyle = r, b.lineWidth = a, b.stroke();
      }
      function D(t, e) {
        b.clearRect(0, 0, x.width, x.height), 1 == e && (b.beginPath(), b.arc(O, C, F - y, X(0) - Math.PI / 2, X(100) - Math.PI / 2), b.strokeStyle = k, b.lineWidth = y, b.stroke()), b.font = T + ' ' + w + ' ' + A, b.textAlign = I, b.textBaseline = 'middle', 'Yes' == g && B(O, C, F - y / 2, X(0) - Math.PI / 2, X(0.1) - Math.PI / 2, a[0], y), B(O, C, F - y, X(0) - Math.PI / 2, X(t) - Math.PI / 2, a[0], y), 'Yes' == g && B(O, C, F - y / 2, X(t - 0.1) - Math.PI / 2, X(t) - Math.PI / 2, a[0], y), b.fillText(t + '%', O, C);
      }
      function L(t, e) {
        if ('object' == typeof t && null !== t) {
          if (t.hasOwnProperty(e))
            return !0;
          for (var o in t)
            if (t.hasOwnProperty(o) && L(t[o], e))
              return !0;
        }
        return !1;
      }
      !function e() {
        1 == r.length && 'stroke' == n ? function () {
          var t = 0;
          if (1 == M && 0 == v)
            var e = setInterval(function () {
              D(++t, v), t >= r[0] && clearInterval(e);
            }, S);
          else if (1 == v) {
            if (0 == M && D(r[0], v), 1 == M)
              var e = setInterval(function () {
                D(++t, v), t >= r[0] && clearInterval(e);
              }, S);
          } else
            D(r[0], v);
        }() : 1 == l && 'normal' == n ? (b.clearRect(0, 0, x.width, x.height), j(), o = z / 100 * Math.PI * 2, b.globalCompositeOperation = 'destination-in', b.beginPath(), b.moveTo(O, C), b.arc(O, C, F, 0, o), b.fill(), b.globalCompositeOperation = 'source-over', z++ < 100 && setTimeout(function () {
          requestAnimationFrame(e);
        }, s)) : (j(), b.beginPath(), b.arc(O, C, F + 5, 0, 2 * Math.PI, !1), b.lineWidth = 30, b.strokeStyle = d, b.stroke());
        var o;
        if (101 == z) {
          if (1 == f && 'inner' == P)
            for (var c = 0; c < r.length; c++) {
              i = r[c] / H * Math.PI * 2;
              var m = C / u, g = 'Top' == p ? O + m * Math.cos(R + i / 2 - Math.PI / 2) : O + m * Math.cos(R + i / 2), k = 'Top' == p ? C + m * Math.sin(R + i / 2 - Math.PI / 2) : C + m * Math.sin(R + i / 2), y = r[c] * (100 / H);
              b.fillStyle = h, b.font = '13px ppb', b.textAlign = 'center', b.fillText(y.toFixed(1) + '%', g, k), R += i;
            }
          if (1 == f && 'horizontal' == P) {
            for (var w = '<div class="pie-horizontal-list"><ul>', c = 0; c < r.length; c++)
              w += '<li><span style="background: ' + a[c] + '"></span> ' + r[c] * (100 / H) + ' %<li>';
            w += '</ul></div>', x.insertAdjacentHTML('afterend', w);
          }
          if (1 == f && 'vertical' == P) {
            for (var w = '<div class="pie-vertical-list-' + t + '"><ul>', c = 0; c < r.length; c++)
              w += '<li><span style="background: ' + a[c] + '"></span> ' + r[c] * (100 / H) + ' %<li>';
            w += '</ul></div>', x.parentElement.insertAdjacentHTML('beforeend', w);
          }
        }
      }();
    }
  },
  id: 'e91183564b',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n\t\t<div class="page" data-name="homeSummary">\r\n\r\n                <!--\r\n\t\t\t\t<div class="navbar navbarBIG no-hairline">\r\n\t\t\t\t\t<div class="navbar-bg"></div>\r\n\t\t\t\t\t<div class="navbar-inner sliding">\r\n\t\t\t\t\t\t<div class="left bTit">&nbsp;';
      r += Template7Helpers.T.call(ctx_1, 'hosu', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\r\n\t\t\t\t\t\t<div class="right">\r\n\t\t\t\t\t\t</div>\r\n\t\t\t\t\t</div>\r\n                </div>\r\n                -->\r\n\r\n\t\t\t\t<div class="page-content">\r\n                    ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.DATA_LOADED, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                        <div class="row no-gap mh100">\r\n                            <div class="col-100 medium-35 paneLCal pd20 mh100 align-self-stretch">\r\n                                <div class="xtrueShadow xmargin mainBT sheetLine">\r\n                                    <div class="Xrow">\r\n                                        <div class="hosucard padding-half">\r\n                                            <div id="calendarM"></div>\r\n                                            \r\n                                            <div class="sep30"></div>\r\n\r\n                                            <div class="htit lmtit">';
          r += Template7Helpers.T.call(ctx_2, 'gstat', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                            <div class="row">\r\n                                                <div class="col cd02 xCP overH" @click="gotoLink(1)">\r\n                                                    <div class="bigT2">';
          r += c(ctx_2.TOTALS.shops, ctx_2);
          r += '</div>\r\n                                                    <div class="subT2">';
          r += Template7Helpers.T.call(ctx_2, 'shops', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                                </div>\r\n                                                <div class="col cd02 xCP overH" @click="gotoLink(2)">\r\n                                                    <div class="bigT2">';
          r += c(ctx_2.TOTALS.users, ctx_2);
          r += '</div>\r\n                                                    <div class="subT2">';
          r += Template7Helpers.T.call(ctx_2, 'users', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                                </div>\r\n                                                <div class="col cd02 xCP overH" @click="gotoLink(3)">\r\n                                                    <div class="bigT2">';
          r += c(ctx_2.TOTALS.providers, ctx_2);
          r += '</div>\r\n                                                    <div class="subT2">';
          r += Template7Helpers.T.call(ctx_2, 'provs', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                                </div>\r\n                                            </div>\r\n\r\n                                            <div class="sep20"></div>\r\n\r\n                                            <!-- <div class="htit lmtit">';
          r += Template7Helpers.T.call(ctx_2, 'nexte', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div> -->\r\n                                            <div class="row">\r\n                                                <div class="overH2 col cd01 ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.TOTALS.TASKS, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'redL';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '" @click="openTasks">\r\n                                                    <div class="bigT">';
          r += c(ctx_2.TOTALS.TASKS, ctx_2);
          r += '</div>\r\n                                                    <div class="subT">';
          r += Template7Helpers.T.call(ctx_2, 'tasksO', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                                </div>\r\n                                                <div class="overH2 col cd01 ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.TOTALS.PROPS, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'redL';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '" @click="openProps">\r\n                                                    <div class="bigT">';
          r += c(ctx_2.TOTALS.PROPS, ctx_2);
          r += '</div>\r\n                                                    <div class="subT">';
          r += Template7Helpers.T.call(ctx_2, 'propsO', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                                </div>\r\n                                            </div>\r\n                                            \r\n                                            <div class="row">\r\n                                                <div class="overH2 col cd01" @click="openWO">\r\n                                                    <div class="bigT">';
          r += c(ctx_2.TOTALS.WO, ctx_2);
          r += '</div>\r\n                                                    <div class="subT">';
          r += Template7Helpers.T.call(ctx_2, 'woO', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                                </div>\r\n                                                <div class="overH2 col cd01 ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.TOTALS.CLOSING, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'redL';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '" @click="openClosing">\r\n                                                    <div class="bigT">';
          r += c(ctx_2.TOTALS.CLOSING, ctx_2);
          r += '</div>\r\n                                                    <div class="subT">';
          r += Template7Helpers.T.call(ctx_2, 'clpr', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                                </div>\r\n                                            </div>\r\n\r\n                                        </div>\r\n                                    </div>\r\n                                </div>\r\n\r\n                            </div>\r\n                            <div class="col-100 medium-65 padSpecial">\r\n                                <div class="flex" style="padding-right: 25px;">\r\n                                    <div style="float:right">\r\n                                        <a href="javascript:app.f7.methods.askCloseSession();" class="link"><i class="f7-icons colorPower">power</i></a>\r\n                                    </div>\r\n                                    <div id="orglogo2"></div>\r\n                               </div>\r\n                                <br>home\r\n                            </div>\r\n                        </div>\r\n                    ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.partnerCODE, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n\r\n                    <div class="row no-gap">\r\n                        <div class="col-100 medium-25 pL">\r\n                            <img src="/static/img/select2.png" width="170" height="170">\r\n                            <!-- -->\r\n                            <br><br>\r\n                            ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.partnerCODE, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                            <div class="oM" @click="openMail">\r\n                                <i class="f7-icons" style="opacity: .5;">envelope</i><br>\r\n                                Enlace para clientes\r\n                                <div class="addr">https://app.seguro-bodas.com/?p=';
              r += c(ctx_3.partnerCODE, ctx_3);
              r += '</div>\r\n                            </div>\r\n                            ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                            <!-- -->\r\n                        </div>\r\n                        <div class="col-100 medium-75 pR">\r\n                            <div class="mtit">Seguro-Bodas.com <span class="spTit">PARTNER</span></div>\r\n                            <div class="mtext">\r\n                                Este es el resumen actual de tu cuenta.<br>\r\n                                <table class="hosuTABLE">\r\n                                    <tr>\r\n                                        <td class="bigTXT rB rBm">48<div class="subTXT">Bodas Aseguradas</div></td>\r\n                                        <td>\r\n                                            <table>\r\n                                                <tr><td class="bigTXT2 rB"><div class="subTXT">Importe total asegurado:</div>128.840,50<span class="eursuf">\u20AC</span></td></tr>\r\n                                                <tr><td>&nbsp;</td></tr>\r\n                                                <tr><td class="bigTXT2 rB"><div class="subTXT">Mi comisión total:</div>2.684,16<span class="eursuf">\u20AC</span></td></tr>\r\n                                            </table>\r\n                                        </td>\r\n                                    </tr>\r\n                                </table>\r\n\r\n                            </div>\r\n                        </div>\r\n                    </div>\r\n        \r\n                    ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n\t\t\t\t</div>\r\n\r\n\t\t</div>\r\n\r\n';
      return r;
    }(this);
  },
  style: `
[data-f7-e91183564b] .pL {
    text-align: right;
    padding-top: 60px;
}
[data-f7-e91183564b] .pR{
    padding: 60px 60px 0 50px;

}
[data-f7-e91183564b] .p30{
    padding: 60px;
}
[data-f7-e91183564b] .mtit{
    font-size: 24pt;
    letter-spacing: -.05em;
    font-family: var(--f7-font-familyB);
    color: var(--f7-theme-color);    
    line-height: 1;
    margin-bottom: 0px;
}
[data-f7-e91183564b] .mtext {
    line-height: 2;
    font-family: var(--f7-font-familyB);
    color: #666;
}
[data-f7-e91183564b] .oM {
    font-size: .7rem;
    font-family: 'ppb';
    color: #888;
    text-align: center;
    /* padding-right: 20px; */
    width: 170px;
    float: right;
    background-color: #e6e8e9;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid transparent;
    cursor: pointer;
}
[data-f7-e91183564b] .oM .addr{
    display: none;
    font-size: 10px;
    font-family: sans-serif;
    margin-top: 15px;
    border-top: 1px solid #cccccc;
    padding-top: 3px;    
}
[data-f7-e91183564b] .oM:hover {
    background-color: #baffba;
    border: 1px solid #97e29a;
    box-shadow: 0 3px 6px rgba(0,0,0,.1);
}
[data-f7-e91183564b] .oM:hover .addr{
    xdisplay: block;
}
`,
  styleScoped: true
};
    