
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import Schart from 'schart.js';
export default {
  data: function () {
    return {
      DATA_LOADED: false,
      CAL_LIST: [],
      TOTAL_WO_MONTH_EVENTS: 0,
      ONLY_SEL_DAY: false,
      TOTALS: {
        TASKS: 0,
        TASKS_CLOSED: 0,
        PROPOSALS: 0,
        PROPOSALS_CLOSED: 0,
        WO: 0,
        WO_CLOSED: 0
      },
      _WIPMARKERS: [],
      gmap: null,
      umap: null
    };
  },
  on: {
    pageInit(e, page) {
    },
    pageAfterIn() {
      var that = this;
      this.$app.emit('selectMenu', '#m_hosu');
    },
    pageBeforeIn() {
      if (this.$app.device.desktop) {
      }
    },
    pageAfterOut() {
    }
  },
  methods: {
    getDBdata() {
    },
    goInit() {
      this.$app.emit('selectMenu', '#m_inv');
      this.$app.views.main.router.navigate('/invoices/');
    }
  },
  id: '95956b8c0b',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n\t\t<div class="page" data-name="homeSummary">\r\n\r\n                <!--\r\n\t\t\t\t<div class="navbar navbarBIG no-hairline">\r\n\t\t\t\t\t<div class="navbar-bg"></div>\r\n\t\t\t\t\t<div class="navbar-inner sliding">\r\n\t\t\t\t\t\t<div class="left bTit">&nbsp;';
      r += Template7Helpers.T.call(ctx_1, 'hosu', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\r\n\t\t\t\t\t\t<div class="right">\r\n\t\t\t\t\t\t</div>\r\n\t\t\t\t\t</div>\r\n                </div>\r\n                -->\r\n\r\n\t\t\t\t<div class="page-content">\r\n                    ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.DATA_LOADED, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                        <div class="row no-gap mh100">\r\n                            <div class="col-100 medium-35 paneLCal pd20 mh100 align-self-stretch">\r\n                                <div class="xtrueShadow xmargin mainBT sheetLine">\r\n                                    <div class="Xrow">\r\n                                        <div class="hosucard padding-half">\r\n                                            <div id="calendarM"></div>\r\n                                            \r\n                                            <div class="sep30"></div>\r\n\r\n                                            <div class="htit lmtit">';
          r += Template7Helpers.T.call(ctx_2, 'gstat', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                            <div class="row">\r\n                                                <div class="col cd02 xCP overH" @click="gotoLink(1)">\r\n                                                    <div class="bigT2">';
          r += c(ctx_2.TOTALS.shops, ctx_2);
          r += '</div>\r\n                                                    <div class="subT2">';
          r += Template7Helpers.T.call(ctx_2, 'shops', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                                </div>\r\n                                                <div class="col cd02 xCP overH" @click="gotoLink(2)">\r\n                                                    <div class="bigT2">';
          r += c(ctx_2.TOTALS.users, ctx_2);
          r += '</div>\r\n                                                    <div class="subT2">';
          r += Template7Helpers.T.call(ctx_2, 'users', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                                </div>\r\n                                                <div class="col cd02 xCP overH" @click="gotoLink(3)">\r\n                                                    <div class="bigT2">';
          r += c(ctx_2.TOTALS.providers, ctx_2);
          r += '</div>\r\n                                                    <div class="subT2">';
          r += Template7Helpers.T.call(ctx_2, 'provs', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                                </div>\r\n                                            </div>\r\n\r\n                                            <div class="sep20"></div>\r\n\r\n                                            <!-- <div class="htit lmtit">';
          r += Template7Helpers.T.call(ctx_2, 'nexte', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div> -->\r\n                                            <div class="row">\r\n                                                <div class="overH2 col cd01 ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.TOTALS.TASKS, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'redL';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '" @click="openTasks">\r\n                                                    <div class="bigT">';
          r += c(ctx_2.TOTALS.TASKS, ctx_2);
          r += '</div>\r\n                                                    <div class="subT">';
          r += Template7Helpers.T.call(ctx_2, 'tasksO', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                                </div>\r\n                                                <div class="overH2 col cd01 ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.TOTALS.PROPS, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'redL';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '" @click="openProps">\r\n                                                    <div class="bigT">';
          r += c(ctx_2.TOTALS.PROPS, ctx_2);
          r += '</div>\r\n                                                    <div class="subT">';
          r += Template7Helpers.T.call(ctx_2, 'propsO', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                                </div>\r\n                                            </div>\r\n                                            \r\n                                            <div class="row">\r\n                                                <div class="overH2 col cd01" @click="openWO">\r\n                                                    <div class="bigT">';
          r += c(ctx_2.TOTALS.WO, ctx_2);
          r += '</div>\r\n                                                    <div class="subT">';
          r += Template7Helpers.T.call(ctx_2, 'woO', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                                </div>\r\n                                                <div class="overH2 col cd01 ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.TOTALS.CLOSING, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'redL';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '" @click="openClosing">\r\n                                                    <div class="bigT">';
          r += c(ctx_2.TOTALS.CLOSING, ctx_2);
          r += '</div>\r\n                                                    <div class="subT">';
          r += Template7Helpers.T.call(ctx_2, 'clpr', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                                </div>\r\n                                                <!--\r\n                                                <div class="col cd01 ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.TOTALS.PROPSU, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'redL';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '" @click="openPropsU">\r\n                                                    <div class="bigT">&nbsp;</div>\r\n                                                    <div class="subT">';
          r += Template7Helpers.T.call(ctx_2, 'pppr', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                                </div>\r\n                                                -->\r\n                                            </div>\r\n                                            \r\n                                            <!--\r\n                                            <div class="row miniT">\r\n                                                <div class="col cd01" @click="openWO">\r\n                                                    <div class="bigT">';
          r += c(ctx_2.TOTALS.WO, ctx_2);
          r += '</div>\r\n                                                    <div class="subT">';
          r += Template7Helpers.T.call(ctx_2, 'woO', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                                </div>\r\n                                                <div class="col cd01" style="cursor: default;">\r\n                                                    <div class="bigT">';
          r += c(ctx_2.TOTAL_WO_MONTH_EVENTS, ctx_2);
          r += '</div>\r\n                                                    <div class="subT">';
          r += Template7Helpers.T.call(ctx_2, 'meO', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</div>\r\n                                                </div>\r\n                                            </div>\r\n                                            -->\r\n\r\n                                            \r\n\r\n\r\n                                        </div>\r\n                                    </div>\r\n                                </div>\r\n\r\n                            </div>\r\n                            <div class="col-100 medium-65 padSpecial">\r\n                                <div class="flex" style="padding-right: 25px;">\r\n                                    <div style="float:right">\r\n                                        <a href="javascript:app.f7.methods.askCloseSession();" class="link"><i class="f7-icons colorPower">power</i></a>\r\n                                    </div>\r\n                                    <div id="orglogo2"></div>\r\n                               </div>\r\n                                <br>home\r\n                            </div>\r\n                        </div>\r\n                    ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n\r\n                    <div class="row no-gap">\r\n                        <div class="col-100 medium-30 pL">\r\n                            <img src="/static/img/pose00.gif" width="170" height="170">\r\n                        </div>\r\n                        <div class="col-100 medium-70 pR">\r\n                            <div class="mtit">Bienvenido a<br>Seguro-Bodas.com</div>\r\n                            <div class="mtext">\r\n                                <br>\r\n                                Ahora puedes asegurar tu boda con LISA Seguros.\r\n                                <br>\r\n                                Ante cualquier imprevisto, podrás organizarla de nuevo sin perder tu dinero.\r\n                                <br><br>\r\n                                Nos adelantamos a tus necesidades.<br>\r\n                                Haz como Maggie y asegura tu boda con nosotros.\r\n                                <br>\r\n                                <br>\r\n                                <div style="display: inline-block;">\r\n                                    <button @click="goInit" class="button button-large button-raised button-fill minBTN">Contratar Mi Boda</button>\r\n                                </div>\r\n                                \r\n\r\n\r\n                            </div>\r\n                        </div>\r\n                    </div>\r\n        \r\n                    ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n\t\t\t\t</div>\r\n\r\n\t\t</div>\r\n\r\n';
      return r;
    }(this);
  },
  style: `
[data-f7-95956b8c0b] .pL {
    text-align: right;
    padding-top: 60px;
}
[data-f7-95956b8c0b] .pR{
    padding: 60px 60px 0 30px;

}
[data-f7-95956b8c0b] .p30{
    padding: 60px;
}
[data-f7-95956b8c0b] .mtit{
    font-size: 24pt;
    letter-spacing: -.05em;
    font-family: var(--f7-font-familyB);
    color: var(--f7-theme-color);    
    line-height: 1;
    margin-bottom: 15px;
}
[data-f7-95956b8c0b] .mtext {
    line-height: 2;
    font-family: var(--f7-font-familyB);
    color: #666;
}
`,
  styleScoped: true
};
    