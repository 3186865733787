
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import myMixin from './mixin-master-detail.js';
export default {
  mixins: [myMixin],
  data() {
    return {
      _PRICE_A: this.$root.FB.GLOBALCONFIG.priceA,
      _PRICE_B: this.$root.FB.GLOBALCONFIG.priceB,
      _DB_TABLE: 'invoices',
      _CACHE_TABLE: 'INVS',
      _MASTER_PAGE: '/invoices/',
      _MASTER_EVENT: 'refreshInvoiceData',
      _INIT_BUY: false,
      totalPrice: '0',
      ACT_DATE: new Date().toISOString().split('T')[0],
      MIN_DATE: this.$root.U.addDays(0).toISOString().split('T')[0],
      MAX_DATE: this.$root.U.addDays(730).toISOString().split('T')[0]
    };
  },
  on: {
    pageInit(e, page) {
      this._ID = this.$route.params.id;
      this._URL_CACHE = this.$root.FB.CACHE[this._CACHE_TABLE];
      if (this._URL_CACHE === null)
        this._URL_CACHE = {};
      this.FORM_DATA_ORIG = this._URL_CACHE[this._ID];
      this._UPDATE_DB_PATH = '/' + this._DB_TABLE + '/' + this.$root.FB.getUID() + '/' + (this.$route.params.id == -1 ? '' : this.$route.params.id);
      this.$app.on('RT_NEWINV', this.reUpdateActual);
      this.$app.on('changeToBuy', this.reUpdateActual);
      if (this._ID != -1) {
        this.totalPrice = this.FORM_DATA_ORIG.prID == '2' ? this._PRICE_B : this._PRICE_A;
        this.$update();
      }
    },
    pageBeforeRemove() {
      this.$app.off('changeToBuy', this.reUpdateActual);
      this.$app.off('RT_NEWINV', this.reUpdateActual);
    },
    pageBeforeIn: function (e, page) {
      this.getInitData();
      this._INIT_BUY = this.FORM_DATA.buy;
    },
    pageAfterIn: function () {
      this.$app.emit('selMasterItem', this._ID);
    }
  },
  methods: {
    cookData4DB(_F) {
      _F.c = 'DD_TIMESTAMP';
      return _F;
    },
    showToolbar() {
      if (this._INIT_BUY)
        return;
      var _el = this.$$(this.$el);
      _el.find('.xtoolbar').removeClass('display-none');
      _el.find('.Istatus').addClass('display-none');
    },
    hideToolbar() {
      var _el = this.$$(this.$el);
      _el.find('.xtoolbar, .Istatus').addClass('display-none');
      _el.find('.Istatus').removeClass('display-none');
      if (this._ID != -1) {
        this.totalPrice = this.FORM_DATA_ORIG.prID == '1' ? this._PRICE_A : this._PRICE_B;
        this.$update();
      }
    },
    pageFullyLoaded() {
      if (this._ID == '-1')
        this.$el.find('INPUT[name="xname"]').focus();
    },
    reUpdateActual() {
      this._URL_CACHE = this.$root.FB.CACHE[this._CACHE_TABLE];
      this.FORM_DATA_ORIG = this._URL_CACHE[this._ID];
      this.$setState({ FORM_DATA: this.$root.U.cloneO(this._URL_CACHE[this._ID]) });
    },
    scrollBottom() {
      var V = this.$el.find('.xbbt')[0];
      if (V)
        V.scrollIntoView({
          block: 'end',
          behavior: 'smooth'
        });
    },
    buyItem() {
      var that = this;
      var U = this.$root.U;
      if (this.$('.input-invalid').length) {
        this.$root.U.showErrorWindow(this.$root.U.getL('accB'));
        return;
      }
      if (!this.$('#acceptOK').prop('checked')) {
        this.$el.find('.miniOK').addClass('mOKred');
        this.$root.U.showErrorWindow(this.$root.U.getL('accA'));
        return;
      }
      let noProfile = false;
      try {
        if (!this.$root.FB.CACHE.CIADATA.CIADATA.cif)
          noProfile = true;
      } catch (ex) {
        noProfile = true;
      }
      if (noProfile) {
        this.$app.dialog.confirm(U.getL('noProf'), 'Perfil incompleto', function () {
          that.$app.views.main.router.navigate('/profile/');
        });
        return;
      }
      if (this._ID != -1) {
        if (!this.$root.FB.isPartner()) {
          var xdata = that.$app.form.convertToData('.o1');
          xdata._ID = this._ID;
          xdata.fPrice = this.totalPrice;
          that.$app.views.main.router.navigate('/paym/' + this._ID + '/', { context: { xdata: xdata } });
        } else {
          this.$app.dialog.confirm(U.getL('pk2'), U.getL('bP'), async function () {
            that.$root.U.showLoader();
            that.$root.FB.functions.httpsCallable('BI')({ i: that._ID }).then(function (result) {
              if (result.data && result.data.res == 'OK') {
                that.$root.FB.CACHE['INVS'][that._ID]['buy'] = true;
                that.$app.emit('changeToBuy');
              } else {
                that.$root.FB._ERROR(result.data && result.data.status ? result.data.status : 'errr ');
              }
              that.$update(that.$root.U.hideLoader);
            }).catch(function (error) {
              that.$root.U.hideLoader();
              that.$root.FB._ERROR(error);
            });
          });
        }
      }
    },
    downloadPDF() {
      var that = this;
      if (that.$root.FB.CACHE['INVS'][that._ID]['tCountry'] == '1.11' || that.$root.FB.CACHE['INVS'][that._ID]['tCountry'] == '1,11') {
        that.$root.U.hideLoader();
        that.$root.U.openNewWin('static/pdf/cert_demo.pdf');
        return;
      }
      ;
      this.$root.U.showLoader();
      try {
        this.getBlobUrlFromPdfStream().then(blobUrl => {
          const link = document.createElement('a');
          link.href = blobUrl;
          link.download = 'Poliza_BODA.pdf';
          link.click();
          that.$root.U.hideLoader();
        }).catch(err => console.log(err));
      } catch (x) {
        this.$root.FB._ERROR(that.$root.U.getL('e017'));
        this.$root.U.hideLoader();
      }
    },
    async getBlobUrlFromPdfStream() {
      var that = this;
      return new Promise(async (resolve, reject) => {
        var tk = await this.$root.FB.auth.currentUser.getIdToken();
        fetch(that.$root.FB.GLOBALCONFIG.strapi + '/pdf', {
          method: 'POST',
          headers: { 'Authorization': 'Bearer ' + tk },
          body: JSON.stringify({
            i: that._ID,
            preventQPDF: that.$root.FB.GLOBALCONFIG.preventQPDF
          })
        }).then(response => response.body).then(body => {
          const reader = body.getReader();
          return new ReadableStream({
            start(controller) {
              return pump();
              function pump() {
                return reader.read().then(({done, value}) => {
                  if (done) {
                    controller.close();
                    return;
                  }
                  controller.enqueue(value);
                  return pump();
                });
              }
            }
          });
        }).then(stream => new Response(stream)).then(response => response.blob()).then(blob => URL.createObjectURL(blob)).then(url => resolve(url)).catch(function (x) {
          console.log(x);
          that.$root.U.hideLoader();
        });
      });
    }
  },
  id: '3aad3ca117',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n    <div class="page" data-name="invdetail">\r\n\r\n      <!-- Page content -->\r\n      <div class="page-content">\r\n\r\n        ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.FORM_DATA, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n        <div class="navbar navbarBIG no-hairline">\r\n            <div class="navbar-bg"></div>\r\n            <div class="navbar-inner sliding">\r\n                ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.isInFullMasterDetail()', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                <div class="left bTit">\r\n                    <a href="#" class="link back">\r\n                        <i class="icon icon-back"></i>\r\n                        <span class="if-not-md mback">Volver</span>\r\n                    </a>&nbsp;';
              r += c(ctx_3.FORM_DATA.xname, ctx_3);
              r += '                  \r\n                </div>\r\n                ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                <div class="left">\r\n                    <a href="#" class="link back">\r\n                        <i class="icon icon-back"></i>\r\n                        <span class="if-not-md mback">Volver</span>\r\n                    </a>\r\n                </div>\r\n                <div class="title bTit">';
              r += c(ctx_3.FORM_DATA.xname, ctx_3);
              r += '</div>\r\n                ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                <div class="right">\r\n                    ';
          r += Template7Helpers.js_if.call(ctx_2, 'this._ID != -1', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                    ';
              r += Template7Helpers.if.call(ctx_3, ctx_3._INIT_BUY, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n                    ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n                        <a href="#" @click="btnDeleteRecord" class="link icon-only color-gray" title="';
                  r += Template7Helpers.T.call(ctx_4, 'delRecord', {
                    hash: {},
                    data: data_4 || {},
                    fn: function empty() {
                      return '';
                    },
                    inverse: function empty() {
                      return '';
                    },
                    root: root,
                    parents: [
                      ctx_3,
                      ctx_2,
                      ctx_1
                    ]
                  });
                  r += '">\r\n                            <i class="f7-icons colorMain">trash</i>\r\n                        </a>\r\n                    ';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\r\n                    ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                </div>\r\n            </div>\r\n        </div>\r\n\r\n        <div class="card fullpage fixHF trueShadow">\r\n            <div class="card-content card-content-padding">\r\n\r\n                    <form class="list o1 ';
          r += Template7Helpers.if.call(ctx_2, ctx_2._INIT_BUY, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'xPEN';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '">\r\n                        <ul>\r\n\r\n                            ';
          r += Template7Helpers.js_if.call(ctx_2, 'this._ID != -1 && !this.FORM_DATA.buy', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                            <div @click="scrollBottom" class="notB">Boda pendiente de asegurar</div>\r\n                            ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                            <div class="sstit">Datos del evento</div>\r\n                            <div class="row no-gap">\r\n                                <div class="col-100 medium-50">\r\n        \r\n                                    <!-- nname -->\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">Nombre de la Boda</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="text" name="xname"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="Nombre de referencia interna"\r\n                                            xdata-error-message="ERmpty"\r\n                                            autocomplete="off"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.xname, ctx_2);
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n\r\n                                </div><div class="col-100 medium-50">\r\n    \r\n                                    <!-- fecha -->\r\n                                    <li class="item-content item-input item-input-outline item-input-with-value">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">Fecha de la Boda</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="date" name="wDate"\r\n                                            ';
          r += Template7Helpers.if.call(ctx_2, ctx_2._INIT_BUY, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                                            ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                                            min="';
              r += c(ctx_3.MIN_DATE, ctx_3);
              r += '" max="';
              r += c(ctx_3.MAX_DATE, ctx_3);
              r += '"\r\n                                            ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="Fecha"\r\n                                            xdata-error-message="ERmpty4"\r\n                                            autocomplete="off"\r\n                                            value="';
          r += Template7Helpers.js_if.call(ctx_2, 'this._ID != -1', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += c(ctx_3.FORM_DATA.wDate, ctx_3);
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += c(ctx_3.ACT_DATE, ctx_3);
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n\r\n                                </div>\r\n                            </div>  \r\n\r\n\r\n                            <div class="row no-gap">\r\n                                <div class="col-100">\r\n                                    <!-- place -->\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">Lugar de celebración</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="text" name="wPlace"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="Nombre del lugar donde se celebra"\r\n                                            xdata-error-message="ERmpty"\r\n                                            autocomplete="off"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.wPlace, ctx_2);
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n                                </div>\r\n                            </div>\r\n\r\n\r\n                            <div class="row no-gap">\r\n                                <div class="col-100">\r\n                                    <!-- address -->\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">Dirección del evento</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="text" name="wAddress"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="Dirección donde se celebra"\r\n                                            xdata-error-message="ERmpty"\r\n                                            autocomplete="off"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.wAddress, ctx_2);
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n                                </div>\r\n                            </div>\r\n\r\n\r\n                            <div class="row no-gap">\r\n                                <div class="col-100 medium-50">\r\n                                    <!-- city -->\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">Ciudad</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="text" name="wCity"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="Ciudad"\r\n                                            xdata-error-message="ERmpty"\r\n                                            autocomplete="off"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.wCity, ctx_2);
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n                                </div>\r\n                                <div class="col-100 medium-50">\r\n                                    <!-- country -->\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">País</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="text" name="wCountry"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="País donde se celebra"\r\n                                            xdata-error-message="ERmpty"\r\n                                            autocomplete="off"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.wCountry, ctx_2);
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n                                </div>\r\n                            </div>\r\n\r\n\r\n                            <div class="row no-gap">\r\n                                <div class="col-100 medium-50">\r\n        \r\n                                    <!-- pax -->\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">Invitados</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="number" step="1" min="1" max="2000" name="wPax"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="Invitados"\r\n                                            xdata-error-message="ERmpty2"\r\n                                            autocomplete="off"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.wPax, ctx_2);
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n\r\n                                </div><div class="col-100 medium-50">\r\n    \r\n                                    <!-- fecha -->\r\n                                    <li class="item-content item-input item-input-outline item-input-with-value">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">Inicio del seguro</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="date" name="wInsuranceStartDate"\r\n                                            ';
          r += Template7Helpers.if.call(ctx_2, ctx_2._INIT_BUY, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                                            ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                                            min="';
              r += c(ctx_3.MIN_DATE, ctx_3);
              r += '" max="';
              r += c(ctx_3.MAX_DATE, ctx_3);
              r += '"\r\n                                            ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="Fecha"\r\n                                            xdata-error-message="ERmpty4"\r\n                                            autocomplete="off"\r\n                                            value="';
          r += Template7Helpers.js_if.call(ctx_2, 'this._ID != -1', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += c(ctx_3.FORM_DATA.wInsuranceStartDate, ctx_3);
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += c(ctx_3.ACT_DATE, ctx_3);
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n\r\n                                </div>\r\n                            </div>  \r\n\r\n                            <hr>\r\n                            <div class="sstit">Datos de la Persona 1</div>\r\n\r\n                            <div class="row no-gap">\r\n                                <div class="col-100 medium-50">\r\n        \r\n                                    <!-- Person1 -->\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">Nombre Persona 1</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="text" name="p1Name"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="Nombre Persona 1"\r\n                                            xdata-error-message="ERmpty"\r\n                                            autocomplete="off"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.p1Name, ctx_2);
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n\r\n                                </div><div class="col-100 medium-50">\r\n                                    <!-- Person1 -->\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">Apellidos Persona 1</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="text" name="p1Surname"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="Apellidos Persona 1"\r\n                                            xdata-error-message="ERmpty"\r\n                                            autocomplete="off"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.p1Surname, ctx_2);
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n\r\n                                </div>\r\n                            </div>\r\n\r\n\r\n                            <div class="row no-gap">\r\n                                <div class="col-100 medium-50">\r\n        \r\n                                    <!-- Person1 -->\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">ID Persona 1</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="text" name="p1ID"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="DNI/Pasaporte Persona 1"\r\n                                            xdata-error-message="ERmpty"\r\n                                            autocomplete="off"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.p1ID, ctx_2);
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n\r\n                                </div><div class="col-100 medium-50">\r\n                                    <!-- Person1 -->\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">eMail Persona 1</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="text" name="p1Email"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="eMail Persona 1"\r\n                                            xdata-error-message="ERmpty"\r\n                                            autocomplete="off"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.p1Email, ctx_2);
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n\r\n                                </div>\r\n                            </div>\r\n\r\n                            <div class="row no-gap">\r\n                                <div class="col-100 medium-50">\r\n        \r\n                                    <!-- Person1 -->\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">Nacionalidad Persona 1</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="text" name="p1Nationality"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="Nacionalidad Persona 1"\r\n                                            xdata-error-message="ERmpty"\r\n                                            autocomplete="off"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.p1Nationality, ctx_2);
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n\r\n                                </div><div class="col-100 medium-50">\r\n                                    <!-- Person1 -->\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">Dirección Persona 1</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="text" name="p1Address"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="Dirección Persona 1"\r\n                                            xdata-error-message="ERmpty"\r\n                                            autocomplete="off"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.p1Address, ctx_2);
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n\r\n                                </div>\r\n                            </div>\r\n\r\n                            <hr>\r\n                            <div class="sstit">Datos de la Persona 2</div>\r\n\r\n                            <div class="row no-gap">\r\n                                <div class="col-100 medium-50">\r\n        \r\n                                    <!-- Person1 -->\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">Nombre Persona 2</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="text" name="p2Name"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="Nombre Persona 2"\r\n                                            xdata-error-message="ERmpty"\r\n                                            autocomplete="off"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.p2Name, ctx_2);
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n\r\n                                </div><div class="col-100 medium-50">\r\n                                    <!-- Person1 -->\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">Apellidos Persona 2</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="text" name="p2Surname"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="Apellidos Persona 2"\r\n                                            xdata-error-message="ERmpty"\r\n                                            autocomplete="off"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.p2Surname, ctx_2);
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n\r\n                                </div>\r\n                            </div>\r\n\r\n\r\n                            <div class="row no-gap">\r\n                                <div class="col-100 medium-50">\r\n        \r\n                                    <!-- Person1 -->\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">ID Persona 2</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="text" name="p2ID"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="DNI/Pasaporte Persona 2"\r\n                                            xdata-error-message="ERmpty"\r\n                                            autocomplete="off"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.p2ID, ctx_2);
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n\r\n                                </div><div class="col-100 medium-50">\r\n                                    <!-- Person1 -->\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">eMail Persona 2</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="text" name="p2Email"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="eMail Persona 2"\r\n                                            xdata-error-message="ERmpty"\r\n                                            autocomplete="off"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.p2Email, ctx_2);
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n\r\n                                </div>\r\n                            </div>\r\n\r\n                            <div class="row no-gap">\r\n                                <div class="col-100 medium-50">\r\n        \r\n                                    <!-- Person1 -->\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">Nacionalidad Persona 2</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="text" name="p2Nationality"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="Nacionalidad Persona 2"\r\n                                            xdata-error-message="ERmpty"\r\n                                            autocomplete="off"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.p2Nationality, ctx_2);
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n\r\n                                </div><div class="col-100 medium-50">\r\n                                    <!-- Person1 -->\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">Dirección Persona 2</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="text" name="p2Address"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="Dirección Persona 2"\r\n                                            xdata-error-message="ERmpty"\r\n                                            autocomplete="off"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.p2Address, ctx_2);
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n\r\n                                </div>\r\n                            </div>\r\n\r\n                            <hr>\r\n                            <div class="sstit">Datos del viaje</div>\r\n\r\n                            <div class="row no-gap">\r\n                                <div class="col-100 medium-50">\r\n                                    <!-- city -->\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">Días</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="number" step="1" min="1" max="60" name="tDays"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="Duración del viaje"\r\n                                            xdata-error-message="ERmpty"\r\n                                            autocomplete="off"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.tDays, ctx_2);
          r += '"/>\r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n                                </div>\r\n                                <div class="col-100 medium-50">\r\n                                    <!-- city -->\r\n                                    <li class="item-content item-input item-input-outline item-input-with-value">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">Fecha salida</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="date" name="tDate"\r\n                                            ';
          r += Template7Helpers.if.call(ctx_2, ctx_2._INIT_BUY, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                                            ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                                            min="';
              r += c(ctx_3.MIN_DATE, ctx_3);
              r += '" max="';
              r += c(ctx_3.MAX_DATE, ctx_3);
              r += '"\r\n                                            ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="Fecha salida viaje"\r\n                                            xdata-error-message="ERmpty4"\r\n                                            autocomplete="off"\r\n                                            value="';
          r += Template7Helpers.js_if.call(ctx_2, 'this._ID != -1', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += c(ctx_3.FORM_DATA.tDate, ctx_3);
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += c(ctx_3.ACT_DATE, ctx_3);
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n                                </div>\r\n                            </div>\r\n\r\n                            <div class="row no-gap">\r\n                                <div class="col-100 medium-33">\r\n                                    <!-- city -->\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">País de salida</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="text" name="tFrom"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="País de origen"\r\n                                            xdata-error-message="ERmpty"\r\n                                            autocomplete="off"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.tFrom, ctx_2);
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n                                </div>\r\n                                <div class="col-100 medium-33">\r\n                                    <!-- city -->\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">Ciudad destino</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="text" name="tDestiny"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="Ciudad destino"\r\n                                            xdata-error-message="ERmpty"\r\n                                            autocomplete="off"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.tDestiny, ctx_2);
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>                                    \r\n                                </div>\r\n                                <div class="col-100 medium-33">\r\n                                    <!-- city -->\r\n                                    <li class="item-content item-input item-input-outline">\r\n                                        <div class="item-inner">\r\n                                        <div class="item-title item-floating-label">País destino</div>\r\n                                        <div class="item-input-wrap">\r\n                                            <input type="text" name="tCountry"\r\n                                            @input="formChanged"\r\n                                            required validate xfdata\r\n                                            xplaceholder="País destino"\r\n                                            xdata-error-message="ERmpty"\r\n                                            autocomplete="off"\r\n                                            value="';
          r += c(ctx_2.FORM_DATA.tCountry, ctx_2);
          r += '"/>       \r\n                                        </div>\r\n                                        </div>\r\n                                    </li>\r\n                                </div>\r\n                            </div>\r\n\r\n                            <hr>\r\n                            <div class="sstit">Seleccione cobertura del seguro</div>\r\n\r\n                            <div class="row no-gap">\r\n                                <div class="col-100">\r\n                                    <li class="pLn">\r\n                                        <label class="radio"><input type="radio" @input="formChanged" name="prID" value="1" ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.FORM_DATA.prID==\'1\' || this._ID == -1', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'checked';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '><i class="icon-radio"></i>3 Estrellas (hasta 10.000\u20AC de cobertura). Precio: ';
          r += c(ctx_2._PRICE_A, ctx_2);
          r += '\u20AC</label><br>\r\n                                        <label class="radio"><input type="radio" @input="formChanged" name="prID" value="2" ';
          r += Template7Helpers.js_if.call(ctx_2, 'this.FORM_DATA.prID==\'2\'', {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += 'checked';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '><i class="icon-radio"></i>4 Estrellas (hasta 20.000\u20AC de cobertura). Precio: ';
          r += c(ctx_2._PRICE_B, ctx_2);
          r += '\u20AC</label>\r\n                                    </li>\r\n                                </div>\r\n                            </div>\r\n\r\n\r\n                            <br><br>\r\n                            <input class="xhid" type="checkbox" name="_ENABLED" checked/>                          \r\n                    \r\n                        </ul>\r\n                    </form>\r\n\r\n                    <div class="Istatus">\r\n                    ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.FORM_DATA.buy, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                        <div class="row">\r\n                            <div class="col-100 medium-30">\r\n                                <div class="ML">\r\n                                    <img src="static/img/bggreen4.svg" width="160" height="160"/>\r\n                                </div>\r\n                            </div>\r\n                            <div class="col-100 medium-70">\r\n                                <div class="MR">\r\n                                    Esta boda está asegurada.<br>\r\n                                    <hr class="hrsep"/><br>\r\n                                    <table align="center">\r\n                                        <tr><td class="tdr">Contratación:</td><td class="tdl">';
              r += Template7Helpers.D2.call(ctx_3, ctx_3.FORM_DATA.c, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '</td></tr>\r\n                                        ';
              r += Template7Helpers.js_if.call(ctx_3, 'this.FORM_DATA.buySTAT === "ok"', {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n                                            <tr><td class="tdr">Póliza número: </td><td class="tdl">LS19 - ';
                  r += c(ctx_4.FORM_DATA.buyLISA, ctx_4);
                  r += '</td></tr>\r\n                                            <tr><td class="tdr">Documento PDF:</td><td class="tdl"><a @click="downloadPDF" href="javascript:void(0);" class="link">Descargar</a></td></tr>\r\n                                        ';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '\r\n                                            <tr><td class="tdr">Póliza número:</td><td class="tdl">Generando póliza...</td></tr>\r\n                                            <tr><td class="tdr">Estado:</td><td class="tdl">Generando póliza...</td></tr>\r\n                                        ';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\r\n                                        </table>\r\n                                    <br>\r\n                                </div>    \r\n                            </div>\r\n                        </div>\r\n                    ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                        <div class="row buy_no">\r\n                            <div class="col-100 medium-30">\r\n                                <div class="ML">\r\n                                    <img src="static/img/alert.gif" width="55" height="50"/><br>\r\n                                    Boda <br>no asegurada\r\n                                </div>\r\n                            </div>\r\n                            <div class="col-100 medium-70">\r\n                                <div class="MR">\r\n                                    Esta boda está pendiente de ser asegurada.<br>\r\n                                    El coste de asegurarla es de tan solo ';
              r += Template7Helpers.F2.call(ctx_3, ctx_3.totalPrice, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\u20AC<br>\r\n                                    <hr class="hrsep"/>\r\n                                    <div class="miniC">\r\n                                        Puede consultar las condiciones generales de contratación del <a href="/ipidmodal/" class="link">seguro aquí.</a><br>\r\n                                    </div>\r\n                                    <div class="miniC miniOK">\r\n                                        <ul class="miniC2 list">\r\n                                        <label class="ios item-checkbox item-content" style="padding:0">\r\n                                            <input type="checkbox" id="acceptOK"/>\r\n                                            <i class="icon icon-checkbox" style="margin-right: .7em;"></i>\r\n                                            <div class="item-inner">\r\n                                            <div class="item-text itx miniC2">Acepto los términos y condiciones <br>de la propuesta previa de seguro.</div>\r\n                                            </div>\r\n                                        </label>\r\n                                        </ul>\r\n                                    </div>\r\n                                    <br>\r\n                                    <div @click="buyItem" class="button button-large button-raised button-fill minBTN xbbt">Comprar Seguro</div>\r\n                                </div>    \r\n                            </div>\r\n                        </div>\r\n                    ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                    </div>\r\n\r\n            </div>\r\n            <div class="card-footer xtoolbar display-none">\r\n                <div>\r\n                    <button @click="btnCancel" class="button button-large button-raised button-fill color-gray minBTN">';
          r += Template7Helpers.T.call(ctx_2, 'cancel', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</button>              \r\n                </div>\r\n                <div>\r\n                    <button @click="btnUpdate" id="btnREGOK" class="button button-large button-raised button-fill minBTN">';
          r += Template7Helpers.T.call(ctx_2, 'update', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '</button>\r\n                </div>\r\n            </div>\r\n        ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n            NO\r\n        ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '                \r\n        </div>\r\n\r\n      </div>\r\n    </div>\r\n';
      return r;
    }(this);
  },
  style: `
[data-f7-3aad3ca117] .icon-radio{
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5rem;    
}
[data-f7-3aad3ca117] .pLn{
    padding-left: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-left) + 2px);    
    margin-top: 10px;
}
[data-f7-3aad3ca117] .pLn LABEL{
    letter-spacing: -.05em;
    font-family: 'ppb';
}
[data-f7-3aad3ca117] .sstit {
    font-size: .8rem;
    font-family: 'pp2';
    color: #999;
    padding-left: calc(var(--f7-list-item-padding-horizontal) + var(--f7-safe-area-left));
}
[data-f7-3aad3ca117] HR {
    border: 0;
    border-bottom: 1px solid #dddddd;
    width: calc(100% - 32px);
    margin: 2rem 16px;
}
[data-f7-3aad3ca117] FORM.o1 LI {
    margin-bottom: 8px;
}
[data-f7-3aad3ca117] .o1 .item-floating-label {
    background-color: transparent !important;
}
.o1 .item-input-focused .item-floating-label,
[data-f7-3aad3ca117] .o1 .item-input-with-value .item-floating-label{
    background-color: #fff !important;
}
[data-f7-3aad3ca117] .notB{
    text-align: center;
    background-color: red;
    margin: 0 1rem 1.5rem;
    text-transform: uppercase;
    font-family: 'pp2';
    font-size: .85rem;
    color: #fff;
    border-radius: 4px;    
    cursor: pointer;
}
`,
  styleScoped: true
};
    