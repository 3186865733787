var M = {};


M.en = {
    //TODO

};
M.es = {
    //gen
    lng: 'Español',
    MONTHS: 'Enero, Febrero, Marzo, Abril, Mayo, Junio, Julio, Agosto, Septiembre, Octubre, Noviembre, Diciembre',
    MONTHSshort: 'E,F,M,A,M,J,J,A,S,O,N,D',
    MONTHS3: 'ENE,FEB,MAR,ABR,MAY,JUN,JUL,AGO,SEP,OCT,NOV,DIC',
    WEEKDAYS: 'Domingo,Lunes,Martes,Miércoles,Jueves,Viernes,Sábado',
    btnOk: 'Aceptar',
    btnCancel: 'Cancelar',
    back: 'Volver',
    welc: 'Bienvenido',
    login: 'Acceder',
    loginI: 'Acceso clientes',
    frgP: '¿Olvidó su contraseña?',
    noAcc1: 'No dispone de una cuenta todavía, puede ',
    noAcc2: 'registrarse gratuitamente aquí',
    yAcc1: 'Si ya dispone de una cuenta de acceso,',
    yAcc2: 'pulse aquí',
    reg: 'Registrarse',
    upassErr: 'Usuario o Contraseña incorrectas',
    loginErr: 'Error de acceso',
    fill: 'Campos incompletos',
    conn: 'Accediendo...',
    errT: 'ERROR',
    errDB: 'Ooops. Lo sentimos mucho.<br>Se ha producido un error accediendo al servidor.<br><br>Inténtelo de nuevo o contacte con soporte.',
    accDis: 'Cuenta no operativa. Contacte con soporte.',
    cancel: 'Cancelar',
    create: 'Añadir',
    update: 'Actualizar',
    passErr: 'eMail o Contraseña erróneos',
    delRecord: 'Borrar boda',
    delOK: 'Elemento eliminado',

    //login
    ema: 'eMail',
    pas: 'Contraseña',
    logP: 'eMail de acceso',
    pasP: 'Contraseña de acceso',
    accT: 'Acepto los <a href="/static/pdf/tc.pdf" target="_blank" class="link external">Términos de Servicio</a> y la <a href="/static/pdf/pp.pdf" target="_blank" class="link external">Política de Privacidad</a>',
    accA: 'Debe aceptar los términos y condiciones',
    accB: 'Deben estar todos los campos rellenados correctamente.',
    mp: 'La contraseña ha de tener al menos 6 caracteres',
    re: 'Preparados para<br>liderar <b>grandes</b> equipos',
    accErr: 'Hay problemas con su cuenta.<br>Contacte con soporte y disculpe las molestias.',

    //left pane
    home:'Inicio',

    copy: 'Copyright &copy; 2024 LISA Smart Insurance. All rights reserved.',
    inv: 'Bodas',
    inc: 'Incidencias',
    load: 'Cargando...',
    search: 'Buscar bodas',
    legal: 'Información Legal',
    noItem: 'Ninguna disponible',
    selItem: 'Seleccione una boda',
    exitSession: '¿Desea cerrar esta sesión?',
    newRecord: 'Añadir Boda',
    delTxt: '¿Está seguro de borrar esta boda?',
    updateOK: 'Datos actualizados',
    basicErr: 'Se ha producido un error',
    ERmpty: 'Este campo es obligatorio',
    ERmpty2: 'Inferior a 2.000',
    ERmpty3: 'Obligatorio entre -5 y +30 días desde hoy',
    ERmpty4: 'Obligatorio entre hoy y dos años máximo',
    cifempty: 'Este campo es obligatorio',
    noProf: 'Para poder realizar la compra, los datos del Perfil deben estar completos.<br>¿Desea ir al Perfil ahora?',
    e016: 'Error cargando medios de pago',
    e017: 'Error descargando PDF',
    bP: 'Asegurar Boda',
    pk: '¿Desear comprar el seguro para esta boda?',
    pk2: '<b>¿Desea asegurar esta boda?</b><br>Una vez comprado no podrá modificar los datos de la misma.',
    e004:'Dirección de eMail ya en uso.<br>Pruebe a crear un nuevo usuario con otro email.',
    e005:'Dirección de eMail no válida.',
    e018: 'Error en el sistema de pago',

};

export default M;