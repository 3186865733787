
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data() {
    return {};
  },
  on: {
    pageInit() {
      this.$app.on('selectMenu', this.selectMenuFromExt);
    },
    pageAfterIn() {
      this.startPreflight();
    },
    pageBeforeRemove() {
      this.$app.off('selectMenu', this.selectMenuFromExt);
    }
  },
  methods: {
    test: function () {
      if (window.test) {
        window.test(this);
        return;
      }
      var that = this;
      that.$root.U.showLoader();
    },
    startPreflight() {
    },
    selectItem(e) {
      var o = this.$$(e.target).closest('LI');
      var forceReloadAll = o.hasClass('rtnew');
      this.hiliteItem(e);
      if (forceReloadAll) {
        this.$app.views.main.router.navigate(e.target.getAttribute('xref'), {
          reloadAll: true,
          context: { FORCE_RELOAD: forceReloadAll }
        });
      } else {
        this.$app.views.main.router.navigate(e.target.getAttribute('xref'));
      }
    },
    hiliteItem(e) {
      var o = this.$$(e.target).closest('LI');
      var forceReloadAll = o.hasClass('rtnew');
      this.unselectAll();
      o.removeClass('rtnew');
      o.addClass('selItem');
      this.closePanel();
    },
    askQ() {
      this.$app.methods.askCloseSession();
    },
    selectMenuFromExt(e) {
      var k = e;
      if (typeof k == 'string') {
        k = e;
      } else {
        k = e.target;
      }
      var o = this.$$(k).closest('LI');
      this.unselectAll();
      o.addClass('selItem');
    },
    unselectAll() {
      this.$$(this.$el).find('UL LI').removeClass('selItem');
    },
    closePanel() {
      this.$app.panel.close('left');
    }
  },
  id: 'edc4f3d76e',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n  <div class="page">\r\n\r\n    <div class="page-content pL">\r\n\r\n      <div class="ATFlogo"><img src="static/img/atf3p.svg"></div>\r\n      <!--<div class="block-title">';
      r += Template7Helpers.T.call(ctx_1, 'ops', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>-->\r\n      <div class="mmenu list links-list no-hairlines no-hairlines-between no-chevron">\r\n        <ul>\r\n          <li id="m_hosu">\r\n            <a @click="hiliteItem" href="/hosuPartner/" data-view=".view-main">Resumen</a>\r\n          </li>\r\n          <li id="m_prof">\r\n            <a @click="hiliteItem" href="/profile/" data-view=".view-main">Mi Perfil</a>\r\n          </li>\r\n          <li id="m_inv">\r\n            <a @click="hiliteItem" href="/invoices/" data-view=".view-main">Bodas</a>\r\n          </li>\r\n          <li id="m_inc">\r\n            <a @click="hiliteItem" href="/inci/" data-view=".view-main">';
      r += Template7Helpers.T.call(ctx_1, 'inc', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</a>\r\n          </li>\r\n          <li id="xhrs"></li>\r\n          <li id="hlp" class="spp">\r\n            <a @click="hiliteItem" class="mn" href="/help/" data-view=".view-main">Ayuda</a>\r\n          </li>\r\n          <li id="leg" class="spp">\r\n            <a @click="hiliteItem" class="mn" href="/legal/" data-view=".view-main">';
      r += Template7Helpers.T.call(ctx_1, 'legal', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</a>\r\n          </li>\r\n          <li id="ipid" class="spp">\r\n            <a @click="hiliteItem" class="mn" href="/ipid/" data-view=".view-main">IPID y Coberturas</a>\r\n          </li>\r\n          <li class="spp">\r\n            <a @click="askQ" href="#" class="mn">Cerrar Sesión</a>\r\n          </li>\r\n\r\n          <li id="xhrs" style="margin-top: 25px;"></li>\r\n          <li class="mnL">\r\n            Con la garantía de:<br>\r\n            <img class="mnLI" src="static/img/axaa3.png"/>\r\n          </li>\r\n\r\n\r\n\r\n        </ul>\r\n      </div>\r\n\r\n\r\n\r\n      <div class="copyV">\r\n        <img src="static/img/logo01.png"><br>\r\n        Seguro-Bodas.com - v';
      r += c(ctx_1.$root.appVersion, ctx_1);
      r += '<br />\r\n        ';
      r += c(ctx_1.$root.getCopy(), ctx_1);
      r += '\r\n      </div>\r\n    </div>\r\n  </div>\r\n';
      return r;
    }(this);
  },
  style: `
[data-f7-edc4f3d76e] #hlp, #leg, #ipid{
    height: 32px;
  }
[data-f7-edc4f3d76e] .mn{
    font-size: 11px;
  }
[data-f7-edc4f3d76e] #xhrs{
    margin-top: 20px;
    border-top: 1px solid rgba(255,255,255,.2);
    margin: 9px 15px 13px 15px;
  }
[data-f7-edc4f3d76e] .copyV {
    position: absolute;
    bottom: 3px;
    font-size: 9px;
    color: #fff;
    left: 0;
    right: 0;
    text-align: center;
    opacity: 0.2;
    font-family: "segoe ui", arial, tahoma, sans-serif;
  }
[data-f7-edc4f3d76e] .links-list a{
    padding-left: 20px;
  }
[data-f7-edc4f3d76e] .links-list a.mn {
    height: 29px !important;
  }  
[data-f7-edc4f3d76e] .spp {
    height: 29px !important;
  } 
[data-f7-edc4f3d76e] .copyV IMG{
    width: 30px;;
  }

[data-f7-edc4f3d76e] .rtnew::after {
    content: " ";
    position: absolute;
    background-color: red;
    left: 4px;
    border-radius: 10px;
    width: 9px;
    height: 9px;
    display: inline-block;
    top: 10px;
  }
[data-f7-edc4f3d76e] .selItem.rtnew::after {
    left: 2px;
  }
[data-f7-edc4f3d76e] .mnL {
    font-family: var(--f7-font-familySys);
    color: rgb(209 162 237 / 45%);
    font-size: 10px;
    padding-top: 5px;
    padding-left: 20px;
    pointer-events: none;
  }
[data-f7-edc4f3d76e] .mnLI{
    width: 120px;
    padding-top: 5px;
    opacity: .5;    
  }
`,
  styleScoped: true
};
    