export default {
  data: function () {
    return {};
  },
  on: {},
  methods: {
    pageFullyLoaded() {
    }
  },
  id: '5fffd6d057',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n\t\t<div class="page" data-name="inci">\r\n\t\t\t\t<div class="page-content margin">\r\n                    <div class="ohno">\r\n                        <img class="ohno" src="static/img/inci2.gif">\r\n                        <br>\r\n\r\n                        De repente, un imprevisto pone en <br>riesgo el día que más esperas...\r\n                        <br>\r\n                        <b>\xA1No estés triste! Lo podemos solucionar.</b>\r\n                        <br><br>\r\n                        Envianos un correo a <a class="link external" href="mailto:incidencias@lisa-seguros.com">incidencias@lisa-seguros.com</a>\r\n                    </div>\r\n                </div>\r\n        </div>\r\n\r\n';
      return r;
    }(this);
  },
  style: `
[data-f7-5fffd6d057] .ohno{
    max-height: 200px;
    text-align: center;
    margin-top: 10%;
    font-family: var(--f7-font-familyB);
    font-size: 9pt;
    color: #888;
}
[data-f7-5fffd6d057] .ohno IMG{
    height: 200px;
    margin-top: 0;
}
`,
  styleScoped: true
};