
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      import myMixin from './mixin-master-master.js';
export default {
  mixins: [myMixin],
  data: function () {
    return {
      _MASTER_EVENT: 'refreshInvoiceData',
      _DETAIL_PAGE: 'invoices/invoiceDetail',
      _FB_DB_LIST: 'getInvoicesList',
      __ACTUAL_FILTER: 'null',
      miniHtxt: '',
      _deferSEL: false
    };
  },
  on: {
    pageInit() {
      this.$app.on(this._MASTER_EVENT, this.refreshData);
      this.$app.on('changeToBuy', this.changeActual);
      this.$app.on('selMasterItem', this.selMasterItem);
      this._fixSearchBar();
    },
    pageBeforeIn() {
      this.getDBdata();
    },
    pageBeforeRemove() {
      this.$app.off(this._MASTER_EVENT, this.refreshData);
      this.$app.off('changeToBuy', this.changeActual);
      this.$app.off('selMasterItem', this.selMasterItem);
    }
  },
  methods: {
    getDBdata(forceReload) {
      var that = this;
      this.$root.U.showLoader();
      this.$root.FB[this._FB_DB_LIST](forceReload, true).then(function (res) {
        if (that.__ACTUAL_FILTER == 2) {
          res = that.$root.U.sortJSONdatesCLS(res, 'c', true, false, 'buyDATA');
        } else if (that.__ACTUAL_FILTER == 3) {
          res = that.$root.U.sortJSONdatesCLS(res, 'c', true, false, 'buy');
        } else if (that.__ACTUAL_FILTER === null) {
        }
        that._renderData(res, forceReload);
      }).catch(that._errGetDBdata);
    },
    changeActual() {
      this.$el.find('UL.FL .nobuy.selItem').removeClass('nobuy');
    },
    finallyDo() {
      var that = this;
      if (this.$el.find('UL.FL .nobuy').length) {
        this.$el.find('.icnReds').removeClass('xhid');
      } else {
        this.$el.find('.icnReds').addClass('xhid');
      }
      if (this._deferSEL) {
        this._deferSEL = false;
        this.$tick(function () {
          that.$el.find('LI.selItem')[0].scrollIntoView({ block: 'end' });
        });
      }
    },
    showFilt(n) {
      this.$el.find('.smop').removeClass('smsel');
      if (n == 1) {
        this.$el.find('.smop.smop1').addClass('smsel');
        this.miniHtxt = 'Todas las Bodas';
        this.__ACTUAL_FILTER = null;
      }
      if (n == 2) {
        this.$el.find('.smop.smop2').addClass('smsel');
        this.miniHtxt = 'Solo Bodas Aseguradas';
        this.__ACTUAL_FILTER = 2;
      }
      if (n == 3) {
        this.$el.find('.smop.smop3').addClass('smsel');
        this.miniHtxt = 'Bodas pendientes de asegurar';
        this.__ACTUAL_FILTER = 3;
      }
      this._deferSEL = true;
      this.getDBdata();
    },
    selMasterItem(id) {
      if (!id)
        return;
      if (this.actualSelected != -1)
        return;
      if (this.actualSelected == -1) {
        this.actualSelected = id;
        this.unselectAll();
        var EL = this.$el.find('li[xitem="' + id + '"]');
        if (EL.length) {
          EL.addClass('selItem');
          EL[0].scrollIntoView({ block: 'end' });
        } else {
          this._deferSEL = true;
        }
      }
    }
  },
  id: '9ffb24391c',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n    <div class="page page-with-subnavbar PAD8" data-name="invmaster">\r\n\r\n        <div class="page-detail-placeholder">\r\n          <div class="selItemW">\r\n          ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.totalR, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n            <img src="static/img/select2.png"/><br>\r\n            ';
          r += Template7Helpers.T.call(ctx_2, 'selItem', {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '<br><br>\r\n          ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.dataLoaded, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n            ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.dataLoaded, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n            <div class="selItemW searchnotfound2">\r\n                <img @click="createNew" src="static/img/noitems3.gif"/><br><!--<img @click="createNew" src="static/img/noitems3b.gif"/><br>-->\r\n                \xA1Oops! No hay nada por aquí...<br>\r\n                Pulsa <div class="miniP">+</div> para añadir una boda.\r\n            </div>\r\n            <div class="miniArr"><img src="/static/img/arrow.gif" width="105" height="140"/></div>\r\n            ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '          \r\n          ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n          </div>\r\n        </div>\r\n\r\n\r\n        <div class="navbar navbarBIG no-hairline">\r\n          <div class="navbar-bg"></div>\r\n          <div class="navbar-inner sliding">\r\n            <div class="left bTit">&nbsp;';
      r += Template7Helpers.T.call(ctx_1, 'inv', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\r\n            <div class="right">\r\n              <!--\r\n                ';
      r += c(ctx_1.totalR, ctx_1);
      r += ' - \r\n              -->\r\n              <!--<a data-reload-detail="true" href="/shopDetail/-1/" class="link icon-only color-gray">\r\n              <a @click="createNew" href="#" class="link icon-only color-gray">\r\n                  <span class="badge color-blue">+</span>\r\n              </a>\r\n              -->\r\n            </div>              \r\n\r\n            <div class="subnavbar no-hairline">\r\n              <div class="subnavbar-inner search100">\r\n                <div class="left">\r\n                  <form class="searchbar searchbar-init no-hairline" data-remove-diacritics="true" data-search-container=".list.xlv" data-search-in="DIV">          \r\n                    <div class="searchbar-inner">\r\n                      <div class="searchbar-input-wrap">\r\n                        <input type="search" placeholder="';
      r += Template7Helpers.T.call(ctx_1, 'search', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.totalR, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += ' ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += ' disabled ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' class="">\r\n                        <i class="searchbar-icon"></i>\r\n                        <span class="input-clear-button"></span>\r\n                      </div>\r\n                    </div>\r\n                  </form>                \r\n                </div>\r\n              </div>\r\n\r\n              <div class="smenu">\r\n                <div class="sm0"></div>\r\n                <div @click="showFilt(1)" title="Ver todas las bodas" class="smop smop1 smsel">Todas</div> \r\n                <!--<div @click="showFilt(2)" title="Mostrar facturas ya aseguradas" class="smop smop2">Aseguradas</div>-->\r\n                <div @click="showFilt(3)" title="Mostrar bodas pendientes de asegurar" class="smop smop3">Pendientes</div>\r\n                <div class="icnReds xhid"></div>\r\n              </div>\r\n            </div>\r\n\r\n          </div>\r\n        </div>\r\n\r\n        <div class="fab fab-right-bottom">\r\n          <a @click="createNew" href="#" title="';
      r += Template7Helpers.T.call(ctx_1, 'newRecord', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '">\r\n            <i class="f7-icons">plus</i>\r\n          </a>\r\n        </div>        \r\n\r\n        <div class="page-content noSC">\r\n            <div class="xcard xcardmaster xtrueShadow card_fullY_SC">\r\n                <div class="card-content">     \r\n                  ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.totalR, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                  <div class="miniH">';
          r += c(ctx_2.miniHtxt, ctx_2);
          r += '</div>     \r\n                  ';
          return r;
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n                  <div class="list media-list xlv xlvAD searchbar-found lvmaster chevron-center no-hairlines no-hairlines-between">\r\n                      ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.totalR, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                      <ul class="FL">\r\n                        ';
          r += Template7Helpers.each.call(ctx_2, ctx_2._DATA_LIST, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                        <li key="';
              r += c(data_3 && data_3.key, ctx_3);
              r += '" @click="selectItem" xitem="';
              r += c(data_3 && data_3.key, ctx_3);
              r += '" class="';
              r += Template7Helpers.js.call(ctx_3, 'this.@key == @root.actualSelected ? \'selItem\':\'\'', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += ' ';
              r += Template7Helpers.if.call(ctx_3, ctx_3._ENABLED, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += 'isDisabled';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += ' ';
              r += Template7Helpers.if.call(ctx_3, ctx_3.buy, {
                hash: {},
                data: data_3 || {},
                fn: function (ctx_4, data_4) {
                  var r = '';
                  r += 'asegurada';
                  return r;
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += 'nobuy pendiente';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '">\r\n                          <!--<a data-reload-detail="true" href="/shopDetail/';
              r += c(data_3 && data_3.key, ctx_3);
              r += '/"> ';
              r += c(data_3 && data_3.key, ctx_3);
              r += ' - ';
              r += c(ctx_3.name, ctx_3);
              r += '</a> -->\r\n                          <a href="#" class="xitem-link item-content">\r\n                              ';
              r += Template7Helpers.if.call(ctx_3, ctx_3._ENABLED, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function (ctx_4, data_4) {
                  var r = '';
                  r += '<div class="item-media"><i class="f7-icons colorMain">hand_raised_slash</i></div>';
                  return r;
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\r\n                              <div class="item-inner">\r\n                                  <div class="xitem-title-row">\r\n                                    <div class="xitem-title">';
              r += c(ctx_3.xname, ctx_3);
              r += '</div>\r\n                                    <div class="xitem-after nosuffix">';
              r += c(ctx_3.wPax, ctx_3);
              r += '</div>\r\n                                    <div class="xitem-text ellip smRR">';
              r += Template7Helpers.D2.call(ctx_3, ctx_3.wDate, {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '</div>\r\n                                    <div class="xitem-text ellip">';
              r += c(ctx_3.wPlace, ctx_3);
              r += '<br>';
              r += c(ctx_3.wCity, ctx_3);
              r += '</div>\r\n                                    <div class="xitem-text ellip" style="margin-right: 3.5rem;">';
              r += c(ctx_3.p1Name, ctx_3);
              r += ' & ';
              r += c(ctx_3.p2Name, ctx_3);
              r += '</div>\r\n                                    <div class="xhid xPEN">';
              r += c(ctx_3.wAddress, ctx_3);
              r += ' ';
              r += c(ctx_3.wCountry, ctx_3);
              r += ' ';
              r += c(ctx_3.wCity, ctx_3);
              r += ' ';
              r += c(ctx_3.tDestiny, ctx_3);
              r += ' ';
              r += c(ctx_3.tCountry, ctx_3);
              r += ' ';
              r += c(ctx_3.tFrom, ctx_3);
              r += ' ';
              r += c(ctx_3.p1Name, ctx_3);
              r += ' ';
              r += c(ctx_3.p1Surname, ctx_3);
              r += ' ';
              r += c(ctx_3.p1ID, ctx_3);
              r += ' ';
              r += c(ctx_3.p1Email, ctx_3);
              r += ' ';
              r += c(ctx_3.p1Nationality, ctx_3);
              r += ' ';
              r += c(ctx_3.p1Address, ctx_3);
              r += ' ';
              r += c(ctx_3.p2Name, ctx_3);
              r += ' ';
              r += c(ctx_3.p2Surname, ctx_3);
              r += ' ';
              r += c(ctx_3.p2ID, ctx_3);
              r += ' ';
              r += c(ctx_3.p2Email, ctx_3);
              r += ' ';
              r += c(ctx_3.p2Nationality, ctx_3);
              r += ' ';
              r += c(ctx_3.p2Address, ctx_3);
              r += '</div>\r\n                                  </div>\r\n                              </div>\r\n                          </a>                    \r\n                        </li>\r\n                        ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                      </ul>\r\n                    ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.dataLoaded, {
            hash: {},
            data: data_2 || {},
            fn: function empty() {
              return '';
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                      <!--\r\n                      ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.dataLoaded, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                      <div class="selItemW searchnotfound">\r\n                          <img @click="createNew" src="static/img/noitems2.gif"/><br>\r\n                          ';
              r += Template7Helpers.T.call(ctx_3, 'noItem', {
                hash: {},
                data: data_3 || {},
                fn: function empty() {
                  return '';
                },
                inverse: function empty() {
                  return '';
                },
                root: root,
                parents: [
                  ctx_2,
                  ctx_1
                ]
              });
              r += '\r\n                      </div>\r\n                      ';
              return r;
            },
            inverse: function empty() {
              return '';
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                      -->\r\n                    ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n                  </div>\r\n              </div>\r\n            </div>\r\n            <div class="block searchnotfound sb3 searchbar-not-found">\r\n                <img @click="clearSearch" src="static/img/notfound.png"/><br>Ninguna boda encontrada\r\n            </div>            \r\n        </div>\r\n\r\n    </div>\r\n    \r\n';
      return r;
    }(this);
  },
  style: `
[data-f7-9ffb24391c] .miniArr{
    position: absolute;
    left: 0;
    bottom: 0;
}
[data-f7-9ffb24391c] .miniArr IMG{
  width: 105px !important;
  height: 140px !important;
}
[data-f7-9ffb24391c] .miniH {
  font-size: 10px;
  font-family: var(--f7-font-familyB);
  text-transform: uppercase;
  color: #aaa;
  letter-spacing: -.05em;
  padding-left: calc(var(--f7-subnavbar-inner-padding-left) + var(--f7-safe-area-right) + 0px);
  margin-bottom: -10px;
}
[data-f7-9ffb24391c] .page-content{
  --f7-navbar-height: 130px;    
}
[data-f7-9ffb24391c] .subnavbar {
  flex-direction: column;
  --f7-subnavbar-height: 100px;
  /*calc(var(--f7-searchbar-input-height) + 4px)*/
}  
[data-f7-9ffb24391c] .subnavbar-inner{
  max-height: 48px;
}
[data-f7-9ffb24391c] .smenu{
  text-align: left;
  width: 100%;
  padding-left:calc(var(--f7-subnavbar-inner-padding-left) + var(--f7-safe-area-right) + 2px);
  flex: 1;
  padding-top: 1rem;
}
[data-f7-9ffb24391c] .smop{
  font-size: 13px;
  display: inline-block;
  font-family: var(--f7-font-familyB);
  letter-spacing: -.05em;
  margin-right: 10px;
  opacity: .5;
  padding-bottom: 2px;
  cursor: pointer;
}
[data-f7-9ffb24391c] .sm0{
  display: inline-block;
  width: calc(var(--f7-subnavbar-inner-padding-left) + var(--f7-safe-area-right) + 2px);
}
[data-f7-9ffb24391c] .smsel{
  opacity: 1;
  border-bottom: 2px solid var(--o1-tx-col);;
}
[data-f7-9ffb24391c] .icnReds {
    display: inline-block;
    width: 7px;
    height: 7px;
    background: red;
    border-radius: 50%;
    margin-left: -10px;
    vertical-align: super;
}
[data-f7-9ffb24391c] .smRR{
    text-align: right;
    margin-top: -.3rem;
    font-family: 'pp';
    color: #aaa;
    letter-spacing: -.02em;
    font-size: 10px;
    font-family: var(--f7-font-familySys);
}

}
`,
  styleScoped: true
};
    