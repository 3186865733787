export default {

    ///////////////////////////////////////////////////////////////
    data: function () {
        return {
            FORM_DATA_ORIG: null,
            FORM_DATA: null
        };
    },  //data


    ///////////////////////////////////////////////////////////////
    methods: {

        fixFormVars(_F){
            return _F;
        },

        cookData4DB(_F){
            return _F;
        },

        //https://stackoverflow.com/questions/2794137/sanitizing-user-input-before-adding-it-to-the-dom-in-javascript        
        sanitizeSTR(string) {
            const map = {
                '&': '&amp;',
                '<': '&lt;',
                '>': '&gt;',
                '"': '&quot;',
                "'": '&#x27;',
                "/": '&#x2F;',
                '`': '&#x60;',
                '=': '&#x3D;'
            };
            const reg = /[&<>"'/`=]/ig;
            return string.replace(reg, (match)=>(map[match]));
        },

        serializeForm(notFix) {
            var that = this;
            var FORM = {};
            FORM = this.$app.form.convertToData(this.$$(this.$el).find('FORM'));
            //fix boolean switches
            Object.keys(FORM).forEach(function(k){
                //if exists
                if ((that.$$('INPUT[name="'+k+'"]')).length) {
                    //if checkbox -> fix it
                    if ( that.$$('INPUT[name="'+k+'"]').attr('type')=='checkbox' ) {
                        FORM[k] = that.$$('INPUT[name="'+k+'"]')[0].checked;
                    }
                    //sanitize
                    if ( that.$$('INPUT[name="'+k+'"]').attr('type')=='text' ) {
                        FORM[k] = that.sanitizeSTR(that.$$('INPUT[name="'+k+'"]')[0].value);
                    }
                }
            });           
            //console.log(FORM)
            return notFix ? FORM : this.fixFormVars(FORM);
        },        

        //getInitData(id) {
            //id = id || this._ID;
        getInitData() {
            var id = this._ID;
            var initDATA = this._INITDATA || {};
            var that = this;
            var U = this.$root.U;
            try{
                //is add new item?
                if (id=='-1'){
                    this.$setState({ FORM_DATA:initDATA }).then(function(){
                        that.forceCheckValues();
                        that.$$(that.$el).find('#btnREGOK').text(that.$root.U.getL('create'));                        
                        that.showToolbar();
                        that.pageFullyLoaded();
                    });
                } else {
                    this.$setState({ FORM_DATA: U.cloneO(this._URL_CACHE[id]) }).then(function(){
                        that.forceCheckValues();
                        that.$$(that.$el).find('#btnREGOK').text(that.$root.U.getL('update'));
                        that.pageFullyLoaded();
                    });
                }
            } catch(e){
                //TODO show MSG not found
                this.$root.U.E(e);
            }
        },

        btnUpdate(){
            var that = this;
            if (!this.isValidForm()) return;
            this.$root.U.showLoader();
            var _F = this.serializeForm();
            _F = this.cookData4DB(_F);

            this.$root.FB._writeDB( this._UPDATE_DB_PATH, _F, (this._ID == '-1'), null, that._CACHE_TABLE, that._ID )
            .then(function(res){
                //ok result from update
                if (res){
                    //was create(id) or updated(true)
                    var _oldID = that._ID;
                    var _refreshAll = (that._ID == '-1');
                    var _newID = false;
                    if (res!==true) {
                        that._ID = res;
                        _newID = res;
                        //new data because its a new record
                        that.FORM_DATA_ORIG = that.$root.U.cloneO(that._URL_CACHE[that._ID]);
                        //if (that._DB_TABLE.toLocaleLowerCase() == 'users'){
                        //    that._UPDATE_DB_PATH = '/' + that._DB_TABLE + '/' + res;
                        //}else{
                            //that._UPDATE_DB_PATH = '/' + that._DB_TABLE + '/' + that.$root.FB.getORG() + '/' + res;
                            that._UPDATE_DB_PATH = '/' + that._DB_TABLE + '/' + res;
                        //}
                        that.$$(that.$el).find('#btnREGOK').text(that.$root.U.getL('update'));
                    }
                    //that.$root.FB._updateCACHE(that._CACHE_TABLE, that._ID, _F);
                    that.$app.emit(that._MASTER_EVENT, {refreshAll:_refreshAll, newID: _newID}); //-> page-shop-master
                    that.FORM_DATA_ORIG = that.$root.U.cloneO(that._URL_CACHE[that._ID]);
                    that.hideToolbar();
                    that.$root.U.showToast(true, that.$root.U.getL('updateOK'));
                    //if (res!==true) that.$app.views.main.router.navigate('/shopDetail/'+res+'/');
                    if (!that.isInFullMasterDetail()){
                        if (that._oldID == '-1'){
                            that.$app.views.main.router.navigate(that._MASTER_PAGE);                                    
                        }else{
                            that.$app.views.main.router.back();
                        }
                    }                        
                }else{
                    that.$root.FB._ERROR(error, that.$root.U.getL('updateNOK') );
                }
            })
            .catch(function(error) {
                //TODO
                //ERROR
                //__lastMSGerrWO = error;
                //StackTrace.fromError(error).then(FB._saveTelemetry).catch(console.log);
                that.$root.FB._ERROR(error, that.$root.U.getL('updateNOK'));
                return false;
            })
            .finally(function() {
                //inside responsive phone (only detail page, not master+detail)
                if (!that.isInFullMasterDetail()){
                    that.$root.U.hideLoader();
                }
            });

        },

        btnDeleteRecord(){
            var that = this;
            if (this._ID == '-1') return;
            that.$app.dialog.confirm(
                this.$root.U.getL('delTxt'), 
                function () {
                    that.$root.U.showLoader();
                    that.$root.FB._deleteTmpDB(that._UPDATE_DB_PATH, that._MASTER_EVENT, that._CACHE_TABLE, that._ID)
                    .then(function(res){
                        if (res){
                            that.$root.U.hideLoader();
                            that.$root.U.showToast(true, that.$root.U.getL('delOK'));
                            if (!that.isInFullMasterDetail()){
                                that.$app.views.main.router.back();
                            }else{
                                that.$app.views.main.router.navigate(that._MASTER_PAGE);                                    
                            }

                        }
                    })
                    .catch(function(error) {
                        //TODO
                        //ERROR
                        //__lastMSGerrWO = error;
                        //StackTrace.fromError(error).then(FB._saveTelemetry).catch(console.log);
                        that.$root.FB._ERROR(error, that.$root.U.getL('delNOK'));
                    });

                    
                }   
            );
        },        

        formChanged(key) {
            if (this.__FORCED_EVENT) return;
            var that = this;
            this.showToolbar();
            var FORM = this.serializeForm();                
            this.$setState({ FORM_DATA: FORM });
            //this.updateCreatedElements(FORM);
        },

        /*
        updateCreatedElements(){
        },
        */

        forceCheckValues(preventComposeInputs){
            if (!preventComposeInputs) this.$root.U.composeInputs(this.$$(this.$el));
            if (this._ID != '-1'){
                this.__FORCED_EVENT = true;
                this.$$('INPUT[type="text"], INPUT[type="number"], INPUT[type="date"]').trigger('change');
                this.$$('TEXTAREA').trigger('change');
                this.__FORCED_EVENT = false;
            }
        },

        btnCancel(_EXT){
            if (_EXT && (_EXT instanceof MouseEvent)) _EXT = this;
            var that = _EXT || this;
            if (that._ID == '-1'){
                if (!that.isInFullMasterDetail()){
                    that.$app.views.main.router.back();
                }else{
                    that.$app.views.main.router.navigate(that._MASTER_PAGE);
                }
                return;
            }
            that.$setState({ FORM_DATA: that.FORM_DATA_ORIG }).then(function(){
                that.forceCheckValues(true);
                that.hideToolbar();
            });
        },    
        
        isValidForm() {
            var res = true;
            this.$$(this.$el).find('INPUT[required]').each(function(o){
                if (res) {
                    if (!this.checkValidity()) {
                        res = false;
                        return false;
                    }
                } 
            });
            return res;
        },
        
        isInFullMasterDetail() {
            //return this.$el.parent().hasClass('view-master-detail');
            return this.$root.U.isInDesktopMasterDetail();
        },

        pageFullyLoaded(){
            //var toggle = this.$app.toggle.get('.toggle');
            //if (toggle) toggle.on('change', this.chkAllOK);
        },

        showToolbar() {
            this.$$(this.$el).find('.xtoolbar').removeClass('display-none');
        },

        hideToolbar() {
            this.$$(this.$el).find('.xtoolbar').addClass('display-none');
        }
    



    }   //methods
  };