
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      INIT: false,
      INV_OK: false,
      INV_MSG: '',
      invName: ''
    };
  },
  on: {
    pageAfterIn() {
      this.checkInvoice();
    },
    pageBeforeIn: function (e, page) {
      this.$app.$('.panel .mmenu').addClass('xhid');
    }
  },
  methods: {
    btnOK() {
      this.$app.$('.panel .mmenu').removeClass('xhid');
      this.$router.navigate('/invoices/invoiceDetail/' + this.IID + '/', { animate: false });
      this.$app.emit('selectMenu', '#m_inv');
    },
    checkInvoice() {
      var that = this;
      var IID = this.IID;
      if (!IID) {
        return;
      }
      this.$root.U.showLoader();
      this.$root.FB.functions.httpsCallable('chI')({ i: IID }).then(function (result) {
        if (result.data && result.data.res == 'OK') {
          that.INV_OK = true;
          that.invName = result.data.invName;
        } else {
          that.INV_OK = false;
          that.INV_MSG = result.data.status;
          that.invName = result.data.invName;
        }
        that.INIT = true;
        that.$update(that.$root.U.hideLoader);
      }).catch(function (error) {
        that.$root.U.hideLoader();
        that.$root.FB._ERROR(error);
      });
    }
  },
  id: 'be7e057088',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\r\n\t\t<div class="page" data-name="whook">\r\n\t\t\t\t<div class="page-content margin">\r\n                    <div class="card fullpage fixHF trueShadow">\r\n                        <div class="pOK">\r\n                            ';
      r += Template7Helpers.if.call(ctx_1, ctx_1.INIT, {
        hash: {},
        data: data_1 || {},
        fn: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                                ';
          r += Template7Helpers.if.call(ctx_2, ctx_2.INV_OK, {
            hash: {},
            data: data_2 || {},
            fn: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                                <img src="static/img/payok.gif" width="105" height="240"><br>\r\n                                <div class="bTit">Pago confirmado</div>\r\n                                <div>Su Boda ';
              r += c(ctx_3.invName, ctx_3);
              r += ' está asegurada</div><br>                \r\n                                <button @click="btnOK" class="bOK button button-large button-raised button-fill minBTN">Continuar</button>              \r\n                                ';
              return r;
            },
            inverse: function (ctx_3, data_3) {
              var r = '';
              r += '\r\n                                <img src="static/img/paynok.gif" width="105" height="240"><br>\r\n                                <div class="bTit">Error en el pago</div>\r\n                                <div class="bTitERR">';
              r += c(ctx_3.INV_MSG, ctx_3);
              r += '</div>\r\n                                <div>Su Boda ';
              r += c(ctx_3.invName, ctx_3);
              r += ' no está asegurada.<br/>Pruebe un nuevo medio de pago. Gracias.</div><br>                \r\n                                <button @click="btnOK" class="bOK button button-large button-raised button-fill minBTN">Continuar</button>              \r\n                                ';
              return r;
            },
            root: root,
            parents: [ctx_1]
          });
          r += '\r\n                            ';
          return r;
        },
        inverse: function (ctx_2, data_2) {
          var r = '';
          r += '\r\n                                <img class="op0" src="static/img/payok.gif" width="105" height="240"><br>\r\n                                <div class="bTit">Comprobando...</div>\r\n                                <div>Espere por favor...</div><br>                \r\n                            ';
          return r;
        },
        root: root,
        parents: [ctx_1]
      });
      r += '\r\n                        </div>\r\n                    </div>\r\n                </div>\r\n        </div>\r\n\r\n';
      return r;
    }(this);
  },
  style: `
[data-f7-be7e057088] .bOK{
    width: 150px;
    margin: 0 auto;
}
[data-f7-be7e057088] .pOK, .pLOAD, .pWIN{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: white;
    z-index: 99;
    border-radius: var(--f7-card-border-radius);
    text-align: center;
    padding-top: 20%;
}
[data-f7-be7e057088] .pOK{
    padding-top: 30px !important;
}
[data-f7-be7e057088] .bTitERR{
    background-color: #ffecec;
    display: inline-block;
    padding: 0 10px;
    border-radius: 3px;
    color: red;
    margin-bottom: 12px;
}

`,
  styleScoped: true
};
    