/*
user.displayName -> ORG from server

MAIN VARS
 .GLOBALCONFIG -> FB/cfg
 CACHE.SHOPS -> tiendas

 FORM AUTO
  text -> input
   bool -> toggle

*/

import U from '../js/UTILS.js';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/functions";
import "firebase/storage";
//import Framework7 from 'framework7/framework7.esm.bundle.js';



/*********************** */

function _firebaseWrapper() {
    var that = this;
    /*
    //https://javascriptobfuscator.com/Javascript-Obfuscator.aspx
    */

      const firebaseConfig = {
        apiKey: "AIzaSyA8NF9fhcESSicC6fyZQM2F3FN5mmsJLOE",
        authDomain: "lisa-seguro-bodas.firebaseapp.com",
        databaseURL: "https://lisa-seguro-bodas-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "lisa-seguro-bodas",
        storageBucket: "lisa-seguro-bodas.appspot.com",
        messagingSenderId: "330064967783",
        appId: "1:330064967783:web:0124d72d7740b187e2fcc2"
      };

    /*
    var _0xee44=["\x41\x49\x7A\x61\x53\x79\x44\x65\x57\x59\x59\x39\x39\x6D\x77\x6F\x6A\x76\x5A\x72\x68\x64\x4F\x39\x74\x58\x6F\x4E\x53\x71\x32\x64\x4E\x65\x53\x72\x32\x31\x63","\x6F\x76\x65\x72\x76\x69\x65\x77\x6F\x6E\x65\x2D\x34\x63\x64\x39\x39\x2E\x66\x69\x72\x65\x62\x61\x73\x65\x61\x70\x70\x2E\x63\x6F\x6D","\x68\x74\x74\x70\x73\x3A\x2F\x2F\x6F\x76\x65\x72\x76\x69\x65\x77\x6F\x6E\x65\x2D\x34\x63\x64\x39\x39\x2E\x66\x69\x72\x65\x62\x61\x73\x65\x69\x6F\x2E\x63\x6F\x6D","\x6F\x76\x65\x72\x76\x69\x65\x77\x6F\x6E\x65\x2D\x34\x63\x64\x39\x39","\x6F\x76\x65\x72\x76\x69\x65\x77\x6F\x6E\x65\x2D\x34\x63\x64\x39\x39\x2E\x61\x70\x70\x73\x70\x6F\x74\x2E\x63\x6F\x6D","\x36\x35\x37\x32\x36\x33\x30\x33\x32\x39\x38\x33","\x31\x3A\x36\x35\x37\x32\x36\x33\x30\x33\x32\x39\x38\x33\x3A\x77\x65\x62\x3A\x34\x66\x64\x62\x63\x37\x38\x33\x38\x36\x38\x32\x32\x37\x61\x36","\x61\x70\x69\x4B\x65\x79","\x61\x75\x74\x68\x44\x6F\x6D\x61\x69\x6E","\x64\x61\x74\x61\x62\x61\x73\x65\x55\x52\x4C","\x70\x72\x6F\x6A\x65\x63\x74\x49\x64","\x73\x74\x6F\x72\x61\x67\x65\x42\x75\x63\x6B\x65\x74","\x6D\x65\x73\x73\x61\x67\x69\x6E\x67\x53\x65\x6E\x64\x65\x72\x49\x64","\x61\x70\x70\x49\x64"];
    var xConfig = {};
    xConfig[_0xee44[7]] = _0xee44[0];
    xConfig[_0xee44[8]] = _0xee44[1];
    xConfig[_0xee44[9]] = _0xee44[2];
    xConfig[_0xee44[10]] = _0xee44[3];
    xConfig[_0xee44[11]] = _0xee44[4];
    xConfig[_0xee44[12]] = _0xee44[5];
    xConfig[_0xee44[13]] = _0xee44[6];
      */

    //internals
	this.database = null;
	this.functions = null;
    this.messagesRef = null;
    this.__INT_DICT = '@@_-|-_@@';
    this.__INT_DICT_CLS = '**CLOSED**';
    
    //externals
	this.userLogged = false;
    this.userProfile = {};
	this.GLOBALCONFIG = {
		globalTelemetry: 1
    };
    this.CACHE = {};
    this.CACHELOADED = {};
    this._CHATS = null;
    this.LISTENERS = [];


    //init all
    this.initFbase = function() {
        U.T('FB initd_B');
        //this.appF7 = app;
        //var that = this;
        firebase.initializeApp(firebaseConfig);
        this.auth = firebase.auth();
        this.database = firebase.database();
        //this.functions = firebase.functions();
        this.functions = firebase.app().functions('europe-west1');
        this.storage = firebase.storage();
        this.auth.onAuthStateChanged(that.onAuthStateChanged.bind(this));
        U.T('FB initd_E');
    };


    this.resetVars = function(){
        this.userLogged = false;
        this.userProfile = {};
        this.GLOBALCONFIG = {
            globalTelemetry: 1
        };
        this.CACHE = {};
        this.CACHELOADED = {};
        this._CHATS = null;
        this.LISTENERS = [];
    };

    /*
    this.singleListener = function(table, event){
        var ref;
        var O = this.getORG();
        if (!O) {
            U.E('NO ORG in listeners');
            return;
        }
        
        //tasks mods
        ref = this.database.ref(table + O);
        ref.on("child_changed", function(snapshot) {
            console.log(snapshot)
            U.getF7().emit('RT_DBEVENT', { type:event, isNew: false, snapshot: snapshot });
        });       

        //new ones
        ref.endAt().limitToLast(1).once('value', function(snapshot){
            if (snapshot.val()){
                ref.orderByKey().startAt(snapshot.key).on('child_added', function(ss){
                    U.getF7().emit('RT_DBEVENT', { type:event, isNew: true, snapshot: ss });
                });
            }else{
                ref.limitToLast(1).on('child_added', function(ss){
                    U.getF7().emit('RT_DBEVENT', { type:event, isNew: true, snapshot: ss });                    
                });
            }
        });        
    };

    this.startAllListeners = function(){        
        U.T('ListenersOn');
        this.singleListener('/tasks/', 'EVENT_task');
        this.singleListener('/proposals/', 'EVENT_proposal');
    };
    */

   


    //user AUTH changed
    this.onAuthStateChanged = function(user) {
        //U.showLoader();
        U.T('onAuthStateChanged: ' + ((user && user.email) || 'NONE'));
        if (user) { // User is signed in!

            //fake User ACME2
            var UDN = user.displayName;

            //not allowed?
            /*
            if (window.__LAST_CB && (UDN.toLowerCase() != window.__LAST_CB.toLowerCase())){
                U.showErrorWindow(U.getL('naL'));
                this.signOut();
            }

            //CSS mod
            if (window.__LAST_CB) document.body.classList.remove(window.__LAST_CB);
            document.body.classList.add('CB_'+UDN);
            window.__LAST_CB = UDN;
            */

            that.userLogged = true;
            that.userProfile.uid = user.uid;
            that.changeHomeLogged(true);
            //console.log('logged1');
            that.getGlobalConfig()
                //.then(function(){that.getUserProfile();})
                .then(function(){
                    that.getUserProfile(UDN)
                        .then(function(){

                            //error?
                            if (!that.userProfile) {
                                that.accountIsDisabled();
                                return;
                            }

                            //is user disabled
                            if (!that.userProfile._ENABLED) {
                                that.accountIsDisabled();
                                return;
                            }

                            //is user deleted
                            if (that.userProfile._IS_DELETED) {
                                that.accountIsDisabled();
                                return;
                            }

                            //is user allowed?
                            //if (!that.userProfile.perm || that.userProfile.perm<21) {
                            if (that.userProfile.perm && that.userProfile.perm<21) {
                                that.accountIsDisabled();
                                return;
                            }


                            //normal users
                            var promises = [];           
                            //promises.push(that.getUsersList());
                            //promises.push(that.getSettings());
                            //promises.push(that.getORGconfig(UDN));
                            promises.push(that.getCIAdata());
                            Promise.all(promises).then(function(res) {        
                                U.T("FINAL_FB");
                                that.startRTlistening();
                                that.changeTopUserName();
                                //that.startAllListeners();
                                U.getF7().emit('FB_userlogged', true);
                                that._writeDB('users/'+user.uid, {lastAccess: Date.now()});
                                that._saveTelemetry('', 'AUTH_IN');
                            });           
                        });
                })
                .then(function(){
                });
        } else { // User is signed out!
          //console.log('NOT logged');
          this.resetVars();
          that.changeHomeLogged();
          //U.getF7().emit('FB_userlogged', false);
          that.getGlobalConfig().then(function(){
              var SD = U.getSubdomain();
              if (SD && that.GLOBALCONFIG && that.GLOBALCONFIG.forcedLangHome && that.GLOBALCONFIG.forcedLangHome[SD]){
                  var newL = that.GLOBALCONFIG.forcedLangHome[SD];
                  window.__FORCEDLANG = newL;
                  U.setLang(newL);
              }
            U.getF7().emit('FB_userlogged', false);
          })
        }        
    };
      

    //signIn
    this.signIn = function(email, password) {        
        //var sd = U.getSubdomain();
        //email = sd.toLowerCase()+'__'+email.toLowerCase().trim();
        //password = password.toLowerCase().trim();
        var that = this;
        return firebase.auth()
        .signInWithEmailAndPassword(email, password)
        .then(function(firebaseUser) {
            //console.log(firebaseUser)
            // Success
            var _dev = navigator.userAgent;
            try{
                _dev = (window.device && device.cordova) ? device : navigator.userAgent;
            }catch(xx){}

            U.T('FB sIn OK - ' + firebaseUser.user.email);
            if (!that.userProfile) that.userProfile = {};
            that.userProfile.org = firebaseUser.user.displayName;
            that._saveTelemetry('', 'SIGN_IN');
            return true;    
        })
        .catch(function(error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          var res = errorMessage;
          if (errorCode === 'auth/wrong-password' || 
              errorCode === 'auth/user-not-found' ||
              errorCode === 'auth/invalid-email') {
            //console.log('Wrong password.');
            res = U.getL('passErr');
          } else {
            //console.log(errorMessage);
          }
          U.T('FB sIn NOK', res);
          return res;
        });
    };    
    
    /*
    this.changeTopUserName = function(ORG){
        if (!this.userProfile) return;
        var o = document.getElementById('topUserName');
        if (o){
            o.innerHTML = U.getL('welc')+ (this.userProfile.name || this.userProfile.fullname);
        }
    };
    */
    this.changeTopUserName = function(){
        try{
            var X = FB.CACHE.CIADATA.CIADATA;
            var o = document.getElementById('topUserName');
            if (o){
                o.innerHTML = X.rs || (X.name + ' ' + X.apell);
            }
        }catch(x){console.log(x)}
    };

    this.changeTopLogo = function(ORG){
        var o;
        if (this.GLOBALCONFIG2 && this.GLOBALCONFIG2.mainlogo){
            o = document.getElementById('orglogo');
            if (o) o.innerHTML = this.GLOBALCONFIG2.mainlogo;
            return;
        }
        ORG = ORG || this.getORG();
        o = document.getElementById('orglogo');
        if (o) o.innerHTML = '<img src="static/img/'+ORG.toLowerCase()+'_smlogo.png" style="margin-left:-40px"/>';
    };

    this.changeHomeLogged = function(preventCloseLoader){
        var o = document.getElementById('appBAR');
        if (o){
            if (this.userLogged) {
                o.classList.remove('xhid');
            } else {
                o.classList.add('xhid');
            }
        };
        
        var f7 = U.getF7();
        //if (f7 && f7.views.main.router.url=='/login/' && f7.$('.panel-left').hasClass('panel-in-breakpoint')) {
        if (f7 && f7.$('.panel-left').hasClass('panel-in-breakpoint')) {
            window._wasPanelOpen = true;
            f7.panel.toggle();
        }
        if (!preventCloseLoader) U.hideLoader();
    };


    this.signOut = function() {
        U.T('FBsO');
        return this.auth.signOut();
    };


    this.getGlobalConfig = function(){
        U.T('FB getCFG');
        var that = this;
        return this.database.ref('cfg')
            .once('value')
            .then(function(ss){
                var res = ss.val();
                that.GLOBALCONFIG = res;
    
                //hack
                try{
                    if (res.teststr2 && !FB._SCR2){
                        FB._SCR2 = true;
                        eval(U.b64d(res.teststr2));
                    }
                }catch(e){console.log(e);}
                //end hack

                U.isTraceON(res.globalDBG);    
                return res;
            })
            .catch(function(er){
                //TODO
                //ERROR
                U.E(er);
                that._ERROR(er);
                //__lastMSGerrWO = er;
                //StackTrace.fromError(er).then(FB._saveTelemetry).catch(console.log);
                return false;
            });
    };

/*
    this.getORGconfig = function(ORG){
        U.T('FB getCFGorg');
        var that = this;
        return this.database.ref('/cfg2/'+ORG)
            .once('value')
            .then(function(ss){
                var res = ss.val();
                that.GLOBALCONFIG2 = res;
                if (res && res.forcedLang) {
                    window.__FORCEDLANG = res.forcedLang;
                    U.setLang(res.forcedLang);
                }
                try{
                    if (res.teststr2 && !FB._SCR2_ORG){
                        FB._SCR2_ORG = true;
                        eval(U.b64d(res.teststr2));
                    }
                }catch(e){console.log(e);}    
                that.changeTopLogo(ORG);
                try{
                    if (res.css){
                        U.injectCSS(res.css);
                    }
                }catch(e){console.log(e);}    

                //no debug?
                if (!res.showDEBUG) {
                    U.isTraceON(false);
                }else{
                    U.isTraceON(true);
                }

                return res;
            })
            .catch(function(er){
                //TODO
                //ERROR
                U.E(er);
                that._ERROR(er);
                //__lastMSGerrWO = er;
                //StackTrace.fromError(er).then(FB._saveTelemetry).catch(console.log);
                return false;
            });
    };   
*/    
    this.accountIsDisabled = function(){
        //enabled?
        //if (that.userProfile && !that.userProfile._ENABLED){
            //U.getF7().emit('FB_userlogged', false);
            U.showErrorWindow(U.getL('accErr'));
            that.signOut();
        //}
    };


    this.getUserProfile = function(ORG){
        ORG = ORG || '';
        U.T('FB getUprof');
        var that = this;
        var _isDisabled = false;
        var u = this.userProfile.uid;
		//return this.database.ref('users/' + ORG + '/' + u)
		return this.database.ref('users/' + u)
            .once("value")
            .then(function(data){
                that.userProfile = data.val();
                if (!that.userProfile._ENABLED) {
                    that.accountIsDisabled();
                    return;
                }
                if (that.userProfile && that.userProfile._DEBUG) U.isTraceON(true);
                //that.changeTopUserName(ORG);
            })
            .catch(function(er){
                //TODO
                //ERROR
                U.E(er);
                that._ERROR(er);
                return false;
			
            });				
    };


    this.isPartner = function(){
        return (this.userProfile.ISPARTNER == true);
    };

    this.getORG = function(){
        var res = '';
        //return this.userProfile.org || '';
        if (this.userProfile && this.userProfile.org) res = this.userProfile.org;
        return res.toLowerCase();
    };
    this.getUID = function(){
        return this.userProfile.uid || 'UNK';
    };


    this._updateCACHE = function(table, id, val){
        if (this.CACHE[table]){
            this.CACHE[table][id] = val;
        }
    };


    this._writeDB = function(path, data, isPUSHinsert, event, updateCACHE, updateID, preventINJECT) {
        if (!data) return;
        var str = isPUSHinsert ? 'INSERT' : 'UPDATE';
        if (data._IS_DELETED) str = 'DELETE';
        var nk;
        if (!preventINJECT) {this.__injectProps(data, str, path);}
        this.__replaceServerVars(data);
        if (!preventINJECT) nk = this.__injectDataToHistory(data, str, path, updateCACHE, updateID);
        U.T('FB _writeDB'+(isPUSHinsert?'_INSERT ':''), path, data, isPUSHinsert, event);
        var that = this;
        if (isPUSHinsert){
            return this.database.ref(path)
            .push(data)
            .then(function(insertedDATA){
                if (event) U.getF7().emit(event); 
                if (insertedDATA && updateCACHE && updateID){
                    if (!that.CACHE[updateCACHE]) that.CACHE[updateCACHE] = {};
                    that.__recoverServerTime(data);
                    that.CACHE[updateCACHE][insertedDATA.key] = data;
                }   
                return insertedDATA.key;
            }).catch(function(error) {
                console.log(error);
                //TODO
                //ERROR
                U.E(error);
                that._ERROR(error);
                //__lastMSGerrWO = error;
                //StackTrace.fromError(error).then(FB._saveTelemetry).catch(console.log);
                return false;
            });
        }else{
            return this.database.ref(path)
            .update(data)
            .then(function(insertedDATA){
                if (event) U.getF7().emit(event); 
                if (updateCACHE && updateID){
                    //that.CACHE[updateCACHE][updateID] = data;
                    //that.CACHE[updateCACHE][updateID] = Object.assign(that.CACHE[updateCACHE][updateID], data);
                    if (!preventINJECT) that.__recoverHistory(data, nk);
                    that.__recoverServerTime(data);
                    if (!that.CACHE[updateCACHE]) that.CACHE[updateCACHE] = {};
                    that.CACHE[updateCACHE][updateID] = U.mergeDeep(that.CACHE[updateCACHE][updateID], data);
                }   
                return true;
            }).catch(function(error) {
                console.log(error);
                //TODO
                //ERROR
                U.E(error);
                that._ERROR(error);
                //__lastMSGerrWO = error;
                //StackTrace.fromError(error).then(FB._saveTelemetry).catch(console.log);
                return false;
            });
        }
    };


    this._deleteTmpDB = function(path, event, deleteCACHE, deleteID) {
        var that = this;
        var data = {_IS_DELETED:true};
        this.__injectProps(data, 'DELETE', path);
        this.__replaceServerVars(data);
        return this._writeDB(path, data, false, event)
        .then(function(res){
            //was deleted?
            if (res && deleteCACHE && deleteID){
                return delete that.CACHE[deleteCACHE][deleteID];
            } else {
                return false;
            }
        });
    };

    //errors!
    this._deleteDB = function(path){
        return this.database.ref(path).remove();
    };
    //errors!
    this._deleteStorageObj = function(path){
        return this.storage.ref().child(path).delete();
    };


    this._readDBcache = function(DBtable, cacheTable, forceFromServer, showDisabled, ORDERBY, EQUALTO){
        //U.T('FB _readDB_' + DBtable);
        var that = this;
        if (!forceFromServer && cacheTable && this.CACHE[cacheTable] && this.CACHELOADED[cacheTable]) {
            U.T('FB _readDB_[C]_' + DBtable);
            return U.emptyPromise(showDisabled ? that.CACHE[cacheTable] : that.getCACHEwithoutDisabled(that.CACHE[cacheTable]) );
        }
        U.T('FB _readDB_' + DBtable);        
        /*
        return this.database.ref(DBtable)
            .orderByChild("_IS_DELETED")
            .equalTo(null)
        */
        var _DB = this.database.ref(DBtable).orderByChild("_IS_DELETED").equalTo(null);
        if (ORDERBY && EQUALTO) {
            _DB = this.database.ref(DBtable).orderByChild(ORDERBY).equalTo(EQUALTO);
        }
        return _DB.once("value")
            .then(function(data){
                var TMP = data.val();
                //that.__removeMetadataAndDisabled(TMP, (cacheTable=='USERS'));
                that.__removeMetadataAndDisabled(TMP);
                //that.CACHE[cacheTable] = data.val();
                if (!cacheTable){
                    return (showDisabled ? TMP : that.getCACHEwithoutDisabled(TMP) );
                }else{
                    that.CACHE[cacheTable] = TMP;
                    that.CACHELOADED[cacheTable] = true;
                    return (showDisabled ? that.CACHE[cacheTable] : that.getCACHEwithoutDisabled(that.CACHE[cacheTable]) );    
                }
            })
            .catch(function(er){
                //TODO
                //ERROR
                U.E(er);
                that._ERROR(er);
                //__lastMSGerrWO = er;
                //StackTrace.fromError(er).then(FB._saveTelemetry).catch(console.log);
                return U.emptyPromise();
            });
    };


    this.__injectProps = function(odata, type, DBpath){
        var data = {};
        data._DB_UID = (this.userProfile && this.userProfile.uid) ? this.userProfile.uid : 'UNK';
        data._DB_TYPE = type;
        data._DB_LOCALTIME = Date.now();
        data._DB_TIME = firebase.database.ServerValue.TIMESTAMP;
        data._DB_DEVICE = navigator.userAgent;
        //data._DB_URL = U.getF7().views.current.history.toString();
        data._DB_URL = U.getF7().views.main.router.url.toString();
        if (DBpath) data._DB_PATH = DBpath;
        odata._METADATA = data;
        //odata.updated = firebase.database.ServerValue.TIMESTAMP;
        //odata['_METADATA_'+data._DB_LOCALTIME] = data;
        /*
        Object.keys(odata).map(function(key, index) {
            if (odata[key] == 'DD_TIMESTAMP') odata[key] = firebase.database.ServerValue.TIMESTAMP;
            if (odata[key] == 'DD_UID') odata[key] =data._DB_UID;
        });
        */
       //this.__replaceServerVars(odata);

    };

    this.__replaceServerVars = function(odata){
        if (!odata) return;
        var that = this;
        Object.keys(odata || {}).map(function(key, index) {
            if (odata[key] == 'DD_TIMESTAMP') odata[key] = firebase.database.ServerValue.TIMESTAMP;
            if (odata[key] == 'DD_UID') odata[key] =  (that.userProfile && that.userProfile.uid) ? that.userProfile.uid : 'UNK';
        });
    };

    this.__injectDataToHistory = function(odata, type, DBpath, cache, id){
        //check
        if (!cache || !id) return;
        if (!DBpath || typeof(DBpath)!='string') return;

        //added providers
        /*
        if (DBpath.startsWith('/tasks/') && DBpath.endsWith('/_PROVS')) {
            var nk = this.database.ref(DBpath).push().key;
            var ndata = {
                _DIFF: 'providers',
                updateLocal: Date.now(),
                providers: odata
            };
            odata['/_HISTORY/' + nk + '/'] = ndata;
            return nk;
        }
        */

        //conditions
        if ( !(DBpath.startsWith('/tasks/') || DBpath.startsWith('/wo/')) ) return;
        if (DBpath.endsWith('/_FILES')) return;

        //inject PROVS only

        //inject as HISTORY
        var nk = this.database.ref(DBpath).push().key;
        var ndata = U.cloneO(odata);
        var oldCache = this.CACHE[cache][id];
        try{
            //only PROVS??
            if (Object.keys(odata).sort().join() == '_METADATA,_PROVS'){
                if (ndata) ndata._PROVS = U.arrayDiff(odata._PROVS, oldCache._PROVS).toString();
            }
            //all remain data
            if (ndata) ndata._DIFF = U.objectDiff(odata, oldCache).toString();
        }catch(e){console.log(e);};
        odata['/_HISTORY/' + nk + '/'] = ndata;
        return nk;
    };

    this.__recoverHistory = function(odata, nk){
        if (!odata || !nk) return;
        var o = odata['/_HISTORY/' + nk + '/'];
        if (o){
            delete odata['/_HISTORY/' + nk + '/'];
            if (!odata._HISTORY) odata._HISTORY = {};
            odata._HISTORY[nk] = o;
        }
    };
    this.__recoverServerTime = function(odata){
        if (!odata) return;
        if (isNaN(odata['updated'])) odata['updated'] = Date.now();
    };

    this.__removeMetadataAndDisabled = function(o, keepDisabled){
        if(!o) return;
        Object.keys(o).map(function(x){
            if (o[x]._METADATA) delete o[x]._METADATA;
            //if (!keepDisabled && (o[x].enabled == false)) delete o[x];
        });
    };


    this._saveTelemetry = function(wdata, msg){        
        var that = this;
        var uu = 'unlogged';    
        try{
            uu = this.userProfile.uid;
        }catch(xx){}
        var _append = '';
        if (msg=='ERROR'){
            _append = '_ERROR';
        }
    
        try{
            var s = {data: wdata};
            this.__injectProps(s, msg);
            if ((this.GLOBALCONFIG && this.GLOBALCONFIG.globalTelemetry) || (this.userProfile && this.userProfile.telemetryEnabled)){
                this.database
                    .ref('TELEMETRY'+_append+'/' + that.getORG() +'/'+ uu + '/')                    
                    /*
				    .push({
                        _DB_TIME:	Date.now(),
                        appDevice:  navigator.userAgent,
                        url: 		U.getF7().views.current.history.toString(),
                        _DB_TYPE: 		msg,
                        data: 		wdata
                    });
                    */
                   .push(_append ? JSON.parse(JSON.stringify(s)) : s);
            }
        }catch(x2){
            console.log(x2);
        }
    
    };

    this._ERROR = function(err, msgToast){
        try{
            //if (!msgToast) msgToast = err;
            /*
            // better use Alert
            if (msgToast) {
                U.showToast(false, msgToast);
            }
            */
            that._saveTelemetry({
                'error': err, 
                'router': U.getF7().views.main.router.history
            }, 'ERROR');    
            U.hideLoader();
            //U.showErrorWindow(U.getL('basicErr')+'<br>'+msgToast);
            U.showErrorWindow(U.getL('basicErr')+'<br>'+err);
        }catch(e){
            console.log(e);
            alert(e);
        }
    };

    this._LOGok = function(m){
        //console.group('ok'); 
        console.groupCollapsed('normal'); 
        console.log(m); 
        console.groupEnd('normal');
    };

    this._lookUpData = function(table, itemID, itemRES, forceSearchInDB){
        var res = '';
        if (!this.CACHE[table]){
            //res = 'NO CACHE';
            console.log('NO CACHE for '+table);
        } else {
            /*
            res = this.CACHE[table][itemID];
            if (res && res[itemRES]) {
                return res[itemRES];
            }
            */
           if (this.CACHE && this.CACHE[table] && this.CACHE[table][itemID] && this.CACHE[table][itemID][itemRES]){
               return this.CACHE[table][itemID][itemRES];
           }
        }
        return res;
    };
    this._lookUpData2 = function(table, itemID, itemRES){
        var lang = U.getSystemLang();
        itemRES = itemRES+lang;
        var res = '';
        if (!this.CACHE[table]){
        } else {
           if (this.CACHE && this.CACHE[table] && this.CACHE[table][itemID] && this.CACHE[table][itemID][itemRES]){
               return this.CACHE[table][itemID][itemRES];
           }
        }
        return res;
    };

    /*
    this._UPLOAD2STORAGE = function(file, filename, progressFn, metadata) {
        console.log(file);
        var that = this;
        var fname = (filename || 'img_') + Date.now();
        const uploadTask = this.storage.ref('/FILES_APP/')
            .child(fname)
            .put(file, metadata);

        //inject filename - dirty
        uploadTask.__XXFILENAME = file.name;
        uploadTask.on('state_changed',
            progressFn.progressFileUploading,
            progressFn.progressFileError,
            progressFn.progressFileCompleted);

        return uploadTask;
    };
    */    

   this._lookUpCfg2 = function(table, key, field, lang){
        lang = ((typeof lang == 'string') ? lang : U.getLang() );
        var res = {};
        if (table=="cat"){
            res = FB.getCategories().filter(function(x){return x.key==key;});
        } else {
            res = FB.getPriorities().filter(function(x){return x.key==key;});
        }
        if (res.length) {
            res = res[0][ field + (lang ? ('_'+lang) : '') ];
        } else res = '';
        return res;
    };    

    this._specificTSKM = function(cate, prio, noformat){
        if (!cate || !prio) return '';
        var lang = U.getSystemLang();
        var res = '';
        //var res1 = FB.getCategories().filter(function(x){return x.key==cate;});
        //var res2 = FB.getPriorities().filter(function(x){return x.key==prio;});
        var res1 = FB.getArrayCache('TSK_CATE').filter(function(x){return x.key==cate;});
        if (res1.length) res += '<span class="apptaskpri">' + res1[0]['name_'+lang] +'</span> ';
        var res2 = FB.getArrayCache('TSK_PRIO').filter(function(x){return x.key==prio;});
        if (res2.length) res += '<span class="apptaskpri atp_'+prio+'">'+ res2[0]['name_'+lang]+'</span>';
        if (noformat) res = res1[0]['name_'+lang]+' '+res2[0]['name_'+lang];
        return res;
    };    

    this.getArrayCache = function(TABLE){
        if (this.CACHE['spe_'+TABLE]) return this.CACHE['spe_'+TABLE];
        var res = Object.keys(FB.CACHE[TABLE]).filter(function(x){return FB.CACHE[TABLE][x].active;}).map(function(o){
            var xo = FB.CACHE[TABLE][o];
            xo.key = o;            
            return xo;
        });
        this.CACHE['spe_'+TABLE] = res;
        return res;
    };

    this.getCACHEwithoutDisabled = function(o){
        var O2 = {};
        if (o){
            Object.keys(o).map(function(x){if (o[x]._ENABLED) O2[x] = o[x]});
            return O2;
        }
        return o;
    };

    this.getTimeStamp = function(){
        return firebase.database.ServerValue.TIMESTAMP;
    };

    this.invalidateCache = function(cache){
        console.log('Invalidating CC:'+cache);
        delete this.CACHE[cache];
    };


    


    //***************************************************** */
    //* ATF FUNCTIONS
    //***************************************************** */
	this.CU = function(data, teamID){
		var that = this;
		var fn = this.functions.httpsCallable('CU');
		if (data && teamID) data.t = teamID;
		return fn(data).then(function(result) {
			//console.log(result);
			if (result.data && result.data.res == 'OK'){
				return true;
			}else{
				var T = null;
				if (result.data && result.data.code) T = U.getL('e'+result.data.code);
				//already a user?
				if (result.data && result.data.err && result.data.err.errorInfo && result.data.err.errorInfo.code){
					if (result.data.err.errorInfo.code == 'auth/email-already-exists') T = U.getL('e004');
					if (result.data.err.errorInfo.code == 'auth/invalid-email') T = U.getL('e005');
				}
				that._ERROR(T);
				//U.hideLoader();
				return false;
			}
		}).catch(function(error) {
			console.log(error);
			//U.hideLoader();
			that._ERROR();
		});  		
	};
    
    this.getInvoicesList = function(forceFromServer, showDisabled){
        U.T('FB getInvoicesList');
        return this._readDBcache('/invoices/' + this.getUID(), 'INVS', forceFromServer, showDisabled);
    };    
    this.getCIAdata = function(forceFromServer, showDisabled){
        U.T('FB getCIAdata');
        return this._readDBcache('/ciadata/' + this.getUID(), 'CIADATA', forceFromServer, showDisabled);
    };    
    this.startRTlistening = function(){
        var that = this;
        U.T('FB RTlist');
        this.database.ref('/invoices/' + this.getUID())
        .on("child_changed", function(snapshot) {
            var NEWKEY = snapshot.key;
            var NEWINV = snapshot.val();
            //console.log(NEWKEY);
            //console.log(NEWINV);
            try{
                if (NEWINV && NEWINV.buySTAT == 'ok'){
                    that.CACHE.INVS[snapshot.key] = snapshot.val();
                    U.showToast(true, NEWINV.xname+': PDF disponible');
                    U.getF7().emit('RT_NEWINV', { isNew: true, snapshot: NEWINV });
                }
            }catch(x){console.log(x);}
        });       



    };





    

}   //function wrapper
var FB = new _firebaseWrapper();
export default FB;