
      import Template7 from 'template7';
      const Template7Helpers = Template7.helpers;
  
      
  
      export default {
  data: function () {
    return {
      ACTUAL_TAB: 'tab2',
      CB_LOGO: null
    };
  },
  on: {
    pageBeforeIn: function (e, page) {
      this.$root.U.composeInputs(this.$el);
    },
    pageAfterIn: function (e, page) {
      this.$app.panel.get('.panel-left').disableVisibleBreakpoint();
      this.$root.U.hideLoader();
    },
    pageInit() {
    }
  },
  methods: {
    checkIsFormInvalid: function (e) {
      this.ACTUAL_TAB = this.$el.find('.tab-link-active').attr('href').substr(1);
      var that = this;
      var isInvalid = 0;
      this.$el.find('#' + this.ACTUAL_TAB + ' INPUT[required]').each(function () {
        if (that.$(this).val().trim().length == 0) {
          isInvalid++;
        }
      });
      return isInvalid == 0 ? false : true;
    },
    showLoginError: function (s) {
      if (typeof s != 'string') {
        this.$el.find('.error').addClass('opa0');
        this.$el.find('.loginBtn').removeClass('button-loading');
        return;
      }
      var that = this;
      setTimeout(function () {
        that.$el.find('.loginBtn').removeClass('button-loading');
      }, 800);
      this.$el.find('.error').text(s).removeClass('opa0');
    },
    isEnter: function (e) {
      if (e.keyCode == 13) {
        this.logIn();
      }
    },
    logIn: function (e) {
      return;
      var that = this;
      this.$el.find('.loginBtn').addClass('button-loading');
      if (this.checkIsFormInvalid(e)) {
        that.showLoginError(that.$root.U.getL('fill'));
        return;
      }
      var f = {};
      f.username = this.$('#uname').val().trim();
      f.password = this.$('#upass').val().trim();
      this.initLogin(f.username, f.password);
    },
    initLogin: function (l, p) {
      return;
      var that = this;
      this.$root.FB.signIn(l, p).then(function (res) {
        if (res === true) {
        } else {
          that.showLoginError(res);
        }
      });
    },
    signUp: function (e) {
      return;
      var U = this.$root.U;
      U.showLoader();
      var that = this;
      var f = {};
      f.p = this.$('#cpass').val().trim();
      f.e = this.$('#cmail').val().trim();
      try {
        let params = new URL(document.location).searchParams;
        var P = params.get('p');
        f.org = P;
      } catch (x) {
      }
      if (this.checkIsFormInvalid(e)) {
        U.hideLoader();
        that.showLoginError(that.$root.U.getL('fill'));
        return;
      }
      if (!this.$('#okCond').prop('checked')) {
        U.hideLoader();
        this.$el.find('.item-checkbox.item-content').addClass('mOKred');
        this.$root.U.showErrorWindow(that.$root.U.getL('accA'));
        return;
      }
      if (f.p.length < 6) {
        U.hideLoader();
        this.$root.U.showErrorWindow(that.$root.U.getL('mp'));
        return;
      }
      that.callCU(f);
    },
    callCU: function (f) {
      return;
      var U = this.$root.U;
      U.showLoader();
      var that = this;
      this.$root.FB.CU(f, null).then(function (res) {
        if (res === true) {
          that.initLogin(f.e, f.p);
        } else {
          U.hideLoader();
        }
      }).catch(function (er) {
        U.hideLoader();
        that.showLoginError(er);
      });
    },
    formChanged: function () {
    },
    selReg: function (t) {
      this.$el.find('#tbm0' + t).click();
    },
    nopass: function () {
      return;
      var that = this;
      try {
        this.$app.dialog.prompt('Introduzca su email y recibirá un correo de confirmación.', null, function (email) {
          that.$root.U.showLoader();
          that.$root.FB.auth.sendPasswordResetEmail(email).then(() => {
            that.$root.U.hideLoader();
            that.$root.U.showSuccessWindow('Email enviado. Compruebe su bandeja de entrada. Gracias');
          }).catch(error => {
            that.$root.U.hideLoader();
            that.$root.U.showErrorWindow('No se ha podido enviar el email para recuperar su contraseña.');
          });
        }, null, this.$('#uname').val().trim() || 'Su email...');
      } catch (x) {
        console.log(x);
        that.$root.U.hideLoader();
        that.$root.U.showErrorWindow('No se ha podido enviar el email para recuperar su contraseña.');
      }
    }
  },
  id: '8c522009ea',
  render() {
    return function (ctx_1, data_1, root) {
      function isArray(arr) {
        return Array.isArray(arr);
      }
      function isFunction(func) {
        return typeof func === 'function';
      }
      function c(val, ctx) {
        if (typeof val !== 'undefined' && val !== null) {
          if (isFunction(val)) {
            return val.call(ctx);
          } else
            return val;
        } else
          return '';
      }
      root = root || ctx_1 || {};
      var r = '';
      r += '\n\t<div class="page" data-name="login">\n\t\t<div class="page-content">\n\n\t\t\t<div class="winLW">\n\t\t\t\t<div class="winL">\n\t\t\t\t\t<div class="L"></div>\n\t\t\t\t\t<div class="R">\n\n\t\t\t\t\t\t\n\t\t\t\t\t\t<div class="tbar xhid">\n\t\t\t\t\t\t\t<a href="#tab1" class="xbutton tab-link" id="tbm01">';
      r += Template7Helpers.T.call(ctx_1, 'login', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</a>\n\t\t\t\t\t\t\t<a href="#tab2" class="xbutton tab-link tab-link-active" id="tbm02">';
      r += Template7Helpers.T.call(ctx_1, 'reg', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</a>\n\t\t\t\t\t\t</div>\n\n\n\t\t\t\t\t\t<div class="tabs-animated-wrap">\n\t\t\t\t\t\t\t<div class="tabs tabs-top">\n\n\t\t\t\t\t\t\t\t<!-- TAB 1 ******************************* -->\n\t\t\t\t\t\t\t\t<div class="tab" id="tab1" @tab:show="showLoginError">\n\t\t\t\t\t\t\t\t\t<div class="login">\n\t\t\t\t\t\t\t\t\t\t<div style="opacity:0;color:transparent;position: absolute;width: 0;height: 0;font-family: var(--f7-font-familyB);">ATF</div>\n\t\t\t\t\t\t\t\t\t\t<img src="static/img/atf0.svg" style="width: 50%;">\n\t\t\t\t\t\t\t\t\t\t<p class="title">';
      r += Template7Helpers.T.call(ctx_1, 'welc', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</p><br>\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t<div class="list no-hairline no-hairlines">\n\t\t\t\t\t\t\t\t\t\t<form class="list o1" autocomplete="off">\n\t\t\t\t\t\t\t\t\t\t\t<ul>\n\t\t\t\t\t\t\t\t\t\t\t<li class="item-content item-input item-input-outline item-input-with-value">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="item-inner">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="item-title item-floating-label">';
      r += Template7Helpers.T.call(ctx_1, 'ema', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="item-input-wrap">\n\t\t\t\t\t\t\t\t\t\t\t\t\t<input id="uname"  autofocus @keydown="isEnter" required type="text" placeholder="';
      r += Template7Helpers.T.call(ctx_1, 'logP', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" name="umail" @input="formChanged" value="">\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t\t\t\t\t\t<li class="item-content item-input item-input-outline item-input-with-value">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="item-inner">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="item-title item-floating-label">';
      r += Template7Helpers.T.call(ctx_1, 'pas', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="item-input-wrap">\n\t\t\t\t\t\t\t\t\t\t\t\t\t<input id="upass" @focus="showLoginError" @keydown="isEnter" required type="text" placeholder="';
      r += Template7Helpers.T.call(ctx_1, 'pasP', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" name="umail" @input="formChanged" value="">\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t\t\t\t\t</form>\n\t\t\t\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t\t\t\t\t<br>\n\t\t\t\t\t\t\t\t\t\t<div class="button button-fill button-preloader loginBtn" @click="logIn">\n\t\t\t\t\t\t\t\t\t\t\t<span class="ios preloader color-white"></span>\n\t\t\t\t\t\t\t\t\t\t\t<span class="txt">';
      r += Template7Helpers.T.call(ctx_1, 'loginI', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</span>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t<div class="error opa0"></div>\n\n\t\t\t\t\t\t\t\t\t\t<div class="lastM">\n\t\t\t\t\t\t\t\t\t\t\t<a @click="nopass" href="#">';
      r += Template7Helpers.T.call(ctx_1, 'frgP', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</a>\n\t\t\t\t\t\t\t\t\t\t\t<br>\n\t\t\t\t\t\t\t\t\t\t\t';
      r += Template7Helpers.T.call(ctx_1, 'noAcc1', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' <a @click="selReg(2)" class="spA" href="#">';
      r += Template7Helpers.T.call(ctx_1, 'noAcc2', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</a>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\n\t\t\t\t\t\t\t\t\t</div>\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t\t\t<!-- TAB 2 ******************************* -->\n\t\t\t\t\t\t\t\t<div class="tab tab-active" id="tab2" @tab:show="showLoginError">\n\t\t\t\t\t\t\t\t\t<div class="login" style="text-align: left;">\n\t\t\t\t\t\t\t\t\t\t<img src="static/img/atf0.svg" style="width: 50%;">\n\t\t\t\t\t\t\t\t\t\t<p class="title">';
      r += Template7Helpers.T.call(ctx_1, 'reg', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</p>\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t<div class="list no-hairline no-hairlines">\n\t\t\t\t\t\t\t\t\t\t<form class="list o1" autocomplete="off">\n\t\t\t\t\t\t\t\t\t\t\t<ul>\n\t\t\t\t\t\t\t\t\t\t\t<!--\n\t\t\t\t\t\t\t\t\t\t\t<li class="item-content item-input item-input-outline item-input-with-value">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="item-inner">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="item-title item-floating-label">';
      r += Template7Helpers.T.call(ctx_1, 'uno', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="item-input-wrap">\n\t\t\t\t\t\t\t\t\t\t\t\t\t<input tabindex="1" id="cname"  @keydown="isEnter" required type="text" placeholder="';
      r += Template7Helpers.T.call(ctx_1, 'unoP', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" name="uname" @input="formChanged" value="">\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t\t\t\t\t\t-->\n\t\t\t\t\t\t\t\t\t\t\t<li class="item-content item-input item-input-outline item-input-with-value">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="item-inner">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="item-title item-floating-label">';
      r += Template7Helpers.T.call(ctx_1, 'ema', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="item-input-wrap">\n\t\t\t\t\t\t\t\t\t\t\t\t\t<input tabindex="2" id="cmail"  @keydown="isEnter" required type="text" placeholder="';
      r += Template7Helpers.T.call(ctx_1, 'logP', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" name="umail" @input="formChanged" value="">\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t\t\t\t\t\t<li class="item-content item-input item-input-outline item-input-with-value">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="item-inner">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="item-title item-floating-label">';
      r += Template7Helpers.T.call(ctx_1, 'pas', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="item-input-wrap">\n\t\t\t\t\t\t\t\t\t\t\t\t\t<input tabindex="3" id="cpass" @focus="showLoginError" @keydown="isEnter" required type="text" placeholder="';
      r += Template7Helpers.T.call(ctx_1, 'pasP', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '" name="umail" @input="formChanged" value="">\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t</li>\n\t\t\t\t\t\t\t\t\t\t\t<li>\n\t\t\t\t\t\t\t\t\t\t\t\t<label class="ios item-checkbox item-content" style="padding:0">\n\t\t\t\t\t\t\t\t\t\t\t\t<input type="checkbox" id="okCond"/>\n\t\t\t\t\t\t\t\t\t\t\t\t<i class="icon icon-checkbox" tabindex="4" style="margin-right: .7em;"></i>\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="item-inner">\n\t\t\t\t\t\t\t\t\t\t\t\t<div class="item-text itx">';
      r += Template7Helpers.T.call(ctx_1, 'accT', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</div>\n\t\t\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t\t\t</label>\n\t\t\t\t\t\t\t\t\t\t\t</li>\t\t\t\t\t\t\t\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t\t</ul>\n\t\t\t\t\t\t\t\t\t\t</form>\n\t\t\t\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t\t\t\t\t<br>\n\t\t\t\t\t\t\t\t\t\t<!--\n\t\t\t\t\t\t\t\t\t\t<div class="button button-fill button-preloader loginBtn" @click="signUp">\n\t\t\t\t\t\t\t\t\t\t\t<span class="ios preloader color-white"></span>\n\t\t\t\t\t\t\t\t\t\t\t<span class="txt">Crear Nueva Cuenta</span>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t\t\t-->\n\t\t\t\t\t\t\t\t\t\t<div style="font-size: .8rem;background-color: red;color: #fff;padding: 1rem;border-radius: .4rem;font-weight: bold;">\n\t\t\t\t\t\t\t\t\t\t\tACTUALIZACIÓN EN CURSO<br>\n\t\t\t\t\t\t\t\t\t\t\tServicio no disponible temporalmente.<br>\n\t\t\t\t\t\t\t\t\t\t\tDiculpe las molestias.\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\n\t\t\t\t\t\t\t\t\t\t<div class="error opa0" style="text-align: center;"></div>\n\n\t\t\t\t\t\t\t\t\t\t<div class="lastM">\n\t\t\t\t\t\t\t\t\t\t\t';
      r += Template7Helpers.T.call(ctx_1, 'yAcc1', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' <a @click="selReg(1)" class="spA" href="#">';
      r += Template7Helpers.T.call(ctx_1, 'yAcc2', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += '</a>\n\t\t\t\t\t\t\t\t\t\t</div>\n\t\t\t\n\t\t\t\t\t\t\t\t\t</div>\t\t\n\t\t\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t</div>\n\n\t\t\t\t\t\t<div class="footer">\n\t\t\t\t\t\t\t';
      r += Template7Helpers.T.call(ctx_1, 'copy', {
        hash: {},
        data: data_1 || {},
        fn: function empty() {
          return '';
        },
        inverse: function empty() {
          return '';
        },
        root: root,
        parents: [ctx_1]
      });
      r += ' - v';
      r += c(ctx_1.$root.appVersion, ctx_1);
      r += '\n\t\t\t\t\t\t</div>\n\n\t\t\t\t\t</div>\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n';
      return r;
    }(this);
  },
  style: `
	#appBAR{
  display: none;
}
.view-main{
  height: 100vh !important;
}
.winLW UL{
	background: transparent;
}
.winLW .o1 .item-floating-label{
	background-color: transparent !important;
	color: #555;
}
.winLW .item-input .item-inner{
	padding-right: 0;
}

.title{
    font-size: 36pt;
    letter-spacing: -.05em;
	font-family: var(--f7-font-family);	
	margin: 0;
}
.view-main {
	height: 100vh !important;
}
.xLOGO {
	width: 170px;
}
.winLW {
	height: 100vh;
    max-width: 1400px;
    margin: 0 auto;	
}
.winL * {
	box-sizing: border-box;
}
.winL {
	display: flex;
	flex-direction: row;
	min-height: 100%;
	min-width: 300px;
}
@media (min-width: 800px){
	.L {
		flex-basis: 50%;
		font-size: 3rem;
		font-weight: 200;
		letter-spacing: 0px;
	}	
	.R {
		flex-basis: 50%;
		padding: 60px 30px;
	}
}
.page-content{
	background: rgb(241 240 238);
	background-image: url(static/img/bg02.jpg);
    background-repeat: no-repeat;
    background-size: cover;
	background-position-x: center;
}
.L{
	color: white;
	font-size: 1.5rem;
	font-weight: 100;
	letter-spacing: 0.1ex;
	min-height: 160px;
	display: flex;
	flex-direction: column;
	-webkit-box-pack: center;
	justify-content: center;
	position: relative;		
	text-align: center;
}
.R{
	align-self: center;
	padding: 1.6rem 20px;
	width: 100%;
	margin: 0px auto;
	text-align: center;
}
.login{
	width: 50%;
    margin: 0 auto;	
}
.tbar{
    justify-content: flex-end;	
}
.loginBtn{
	width: 70%;
    margin: 0 auto;
    min-height: 2rem;
    line-height: 2rem;
    max-width: 200px;	
}
.lastM{
    font-size: .75rem;
    margin-top: 2rem;
    font-family: var(--f7-font-familyB);
    letter-spacing: -.04em;
    opacity: .9;
}
.spA{
	font-family: var(--f7-font-familySB);
	opacity: .7;
	text-decoration: underline;
}
.spA:hover{
	opacity: 1;
	color: var(--f7-theme-color);
}
UL{
	background: transparent;
}
FORM.o1 INPUT {
    min-height: 44px;
    line-height: 44px;
    font-size: 1rem;	
}
FORM.o1 LI {
    margin-bottom: 14px;
}
.tbar A.tab-link{
	padding-bottom: 0; 
}
.error{
	background-color: rgb(255 232 232);
    color: var(--REDDARK);
    border-radius: 4px;
    /* padding: .4rem 0; */
	margin: 1rem -2rem 0 -2rem;
	font-family: var(--f7-font-familySB);
}
.button-preloader .preloader{
	display: none;
}
.button-preloader.button-loading{
	pointer-events: none;;
}
.button-preloader.button-loading .preloader{
	display: block !important;
}
.button-preloader.button-loading .txt{
	display: none;
}
.preloader{
    margin: 0 auto;
	margin-top: 6px;
}

.item-input-wrap{
	border-radius: 6px;
}

.item-floating-label{
	background: transparent !important;
    padding: 0px 0 0 8px;
}

.item-input-outline.item-input-with-value .item-floating-label, 
.item-input-outline.item-input-focused .item-floating-label {
	transform: scale(1) translateY(-10%);
	padding: 0;
}
LI{
	padding-left: 0 !important;
}
.item-inner{
	padding-right: 0;
}
.itx{
    font-size: 12px !important;
    text-align: left;
    line-height: 1.3 !important;
    color: hsl(0, 0%, 25%) !important;
    font-family: var(--f7-font-familyB);
}
.itx A{
    font-family: var(--f7-font-familySB);
    color: hsl(0, 0%, 45%);
    text-decoration: underline;
}
.footer{
    font-size: 10px;
    position: fixed;
    bottom: 4px;
    left: 50%;
    right: 0;
	color: #c783f0;
	}
.mtit{
	letter-spacing: -.02em;
    line-height: 1.2;
	font-size: 2rem;		
    position: absolute;
    left: 0;
    right: 0;
	top: 20%;	
    text-shadow: 1px 3px 5px rgba(0,0,0,.6);	
}
.mrk{
    margin: 0 auto;
    position: relative;
    width: 70%;
    xbackground-color: rgba(0,0,0,.2);
	flex: 1;
}
.mrk .mlogo{
	xfilter: drop-shadow(6px 12px 8px rgba(0,0,0,.5));	
	height: 14px;
	margin-top: 2rem;
}
.mrk .pplane{
	filter: drop-shadow(6px 12px 8px rgba(0,0,0,.5));	
    position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
}

`,
  styleScoped: false
};
    