export default {

    ///////////////////////////////////////////////////////////////
    data: function () {
        return {
            //********************************
            //** CHANGES
            //_MASTER_EVENT: 'refreshShopsData',
            //_DETAIL_PAGE: 'shopDetail',
            //pageName -> HTML
            //getShopsList -> FB.dbLIST
            //********************************
            fromMixin: true,
            isInitd : false,
            allowInfinite: true,
            hasMoreItems: true,
            lastPage: 1,
            _DATA_LIST: [],
            indexTopic: 0,
            totalR:0,
            dataLoaded:false,
            actualSelected: -1
        };
    },  //data


    ///////////////////////////////////////////////////////////////
    methods: {

      refreshData(e){
        if (e && e.newID){
          this.actualSelected = e.newID;
        }
        this.getDBdata(e ? e.refreshAll : false);
      },


      _renderData(res, forceReload) {
        var that = this;
        that.$setState({
            dataLoaded:true,
            _DATA_LIST: res ? res : [],
            totalR: res ? Object.keys(res).length : 0,
            actualSelected: that.actualSelected
        }).then(function(){
          //if (that.actualSelected != -1 && forceReload) that.$root.U.scrolltoLastLI( that.$$(that.$el).find('.lvmaster LI') );
          if (that.actualSelected != -1 && forceReload) that.$root.U.scrolltoLastLI( that.$$(that.$el).find('.lvmaster') );
          //
          if (that.FORCE_RELOAD) {
            that.FORCE_RELOAD = false;
            ///if (that.filterOnlyChat) that.filterOnlyChat();
          }
          //
          that._restoreFilter();
          //that.$root.U.hideLoader();
          setTimeout(that.$root.U.hideLoader, 350);
          that.finallyDo();          
        });
      },

      _fixSearchBar(){
        var that = this;
        try{
          var SB = this.$app.searchbar.get();
          if (SB) this.$app.searchbar.destroy('.searchbar');
          var RES = this.$app.searchbar.create({
            el: '.searchbar',
            removeDiacritics: true,
            searchContainer: this.$el.find('.searchbar').attr('data-search-container') || '.xlv',
            searchIn: this.$el.find('.searchbar').attr('data-search-in') || 'div',
            //searchContainer: '.xlv',
            //searchIn: 'div',
            on: {
              search(sb, query, previousQuery) {
                //console.log(query, previousQuery);
                that._restoreFilterHack();
              },
              clear(){
                that._restoreFilterHack();
              }
            }
          });
          return RES;
        }catch(ex){console.log(ex);}
      },


      //hack search box -> visible title
      _restoreFilterHack(){
        if (this.$el.find('.searchnotfound.searchbar-not-found').css('display') == 'none'){
          this.$el.find('.xcard .miniH').css('display', 'block');
        }else{
          this.$el.find('.xcard .miniH').css('display', 'none');
        }
      },

      _restoreFilter(){
        ///var sb = this.$app.searchbar.get(this.$el.find('.searchbar'));          
        var sb = this._fixSearchBar();
        //var wasQueryPending = sb.query;
        var wasQueryPending = this.$el.find('input[type="search"]').val();
        if (sb && wasQueryPending) {
          this.__PREVENTSEARCHFOCUS = true;
          sb.clear();
          sb.search(wasQueryPending);
          var Q = sb.$el.find('.autoS')[0];
          if (Q) Q.blur();
        }
      },


      _errGetDBdata(e){
          console.log("EErr44");
          console.log(e);
          this.$root.U.hideLoader();
      },


      selectItem(e) {
        var o = this.$$(e.target).closest('LI');
        this.actualSelected = o.attr('xitem');
        this.unselectAll();
        o.addClass('selItem');
        
        if (this.$router.currentRoute.url.startsWith('/paym/')){
          this.$app.views.main.router.back({animate: false});
        }
        this.$app.views.main.router.navigate('/'+this._DETAIL_PAGE+'/'+this.actualSelected+'/', {reloadDetail:true});
        
        //this.$app.views.main.router.navigate('/'+this._DETAIL_PAGE+'/'+this.actualSelected+'/', {reloadDetail:true});
        //this.$$(this.$el).find('.fab').show();
      },


      unselectAll() {
        this.$$(this.$el).find('UL LI').removeClass('selItem');
      },


      createNew() {
        this.unselectAll();
        //this.$$(this.$el).find('.fab').hide();
        if (this.$root.U.isInDesktopMasterDetail()){
          this.$app.views.main.router.navigate('/'+this._DETAIL_PAGE+'/-1/', {reloadDetail:true, reloadCurrent:true});
        }else{
          this.$app.views.main.router.navigate('/'+this._DETAIL_PAGE+'/-1/');
        }
        this.clearSearch();
      },


      clearSearch() {
        var sbar = this.$app.searchbar.get(this.$$(this.$el).find('.searchbar'));
        sbar.clear();
        //console.log(this.totalR)
      },


      filterData(e){
        console.log(e.value);

      },      



    }   //methods
  };